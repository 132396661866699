import React, { Fragment, useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import RegisterForm from '../form/RegisterForm'
import { useRegisterMutation } from '../../store/api/authApi'
import {toast} from 'react-toastify'
import {useLocation,useNavigate} from 'react-router-dom'
import Loader from '../../utils/Loader'

const Register = () => {
    const navigate=useNavigate()
    const [values, setValues]=useState({email:'',password:'',name:'',confirmPassword:'',phoneNumber:''})
    
    const {email,password,name,confirmPassword,phoneNumber}=values
    const [register,{isLoading,isError,error,data, isSuccess}]=useRegisterMutation()
  
  
    const handleChange=(e)=>{
      setValues({...values,[e.target.name]: e.target.value})
    }
    const handleSubmit=(e)=>{
        e.preventDefault()
        
        try {
          const data={
            email: email,
            password: password,
            name: name,
            confirmPassword: confirmPassword,
            phoneNumber: phoneNumber

          }
       
          register(data)
          
        } catch (error) {
         
        }
  }

  useEffect(()=>{
    if(isError){
      toast.error(error?.data?.message)
    }
    if(isSuccess){
      toast.success(data?.message)
    }
    if(data?.success){
      navigate("/login")
    }
  },[isError, isSuccess])

if(isLoading) return <Loader/>
  
  return (
   <Fragment>
     <div className="breadcrumbs">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="breadcrumbs-content">
                        <h1 className="page-title">Registration</h1>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <ul className="breadcrumb-nav">
                        <li><Link to="/home"><i className="lni lni-home"></i> Home</Link></li>
                        <li>Registration</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <div className="account-login section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="register-form">
                        <div className="title">
                            <h3>No Account? Register</h3>
                            <p>Registration takes less than a minute but gives you full control over your orders.</p>
                        </div>

     <RegisterForm isLoading={isLoading} values={values} handleChange={handleChange} handleSubmit={handleSubmit}/>


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
   </Fragment>
  )
}

export default Register
