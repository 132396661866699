import React,{Fragment, useState,useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import Loader from '../../utils/Loader'
import ResetPasswordForm from '../form/ResetPasswordForm'
import {toast} from 'react-toastify'
import MetaData from '../../utils/MetaData'
import { useResetPasswordMutation } from '../../store/api/userApi'
const ResetPassword = () => {

    const [values,setValues]=useState({password:'',confirmPassword:''})
    const {password,confirmPassword}=values
    const [resetPassword,{isLoading, isError,data,isSuccess,error}]=useResetPasswordMutation()
   
    const params=useParams();
    const {resetToken}=params;

    
const handleSubmit=async(e)=>{
   e.preventDefault();
   const userData={
   
     password: password,
     confirmPassword: confirmPassword
     
 
    }
   try {
  
    resetPassword({token:resetToken, body: userData})
 
    
     
   } catch (error) {
    toast.error(error?.data?.message)
   }
  }
  const handleChange=(e)=>{
   setValues({ ...values, [e.target.name]: e.target.value });
  }

  useEffect(()=>{
    if(isError){
        toast.error(error?.data?.message)
    }
   
},[isError])


useEffect(()=>{
  if(isSuccess){
      toast.success(data?.message)
  }   setValues({password:'', confirmPassword:''})
 
},[isSuccess])




    if(isLoading) return <Loader/>

  return (
    <Fragment>
         <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="breadcrumbs-content">
                        <h1 class="page-title">Reset Password</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <ul class="breadcrumb-nav">
                        <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                        <li>Reset Password</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <div class="account-login section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
        <MetaData title={"reset"}/>
        <ResetPasswordForm values={values} isLoading={isLoading} handleChange={handleChange} handleSubmit={handleSubmit}/>
        </div>
                    
                    </div>
                        
                    </div>
                        
                    </div>
    </Fragment>
  )
}

export default ResetPassword