import React, { Fragment, useEffect, useState } from 'react'
import LoginForm from '../form/LoginForm'
import { Link } from 'react-router-dom'
import MetaData from '../../utils/MetaData'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import Loader from '../../utils/Loader'
import { useLoginMutation } from '../../store/api/authApi'

const Loging = () => {

  const navigate=useNavigate()
  const [values, setValues]=useState({email:'',password:''})
  const {isAuthenticated,user}=useSelector(state=>state.auth)
  const {cartItems}=useSelector(state=>state.cart)
  const {email,password}=values
  const [login,{isLoading,isError,error,data, isSuccess}]=useLoginMutation()
  useEffect(()=>{
    if(isAuthenticated && user?.role==='user'){
     if(cartItems.length!==0){
      navigate("/cart")
     }else{
      navigate("/home")
     }
    }
    if(isAuthenticated && user?.role==='admin'){
      navigate("/dashboard")
    }
    if(isError){
      toast.error(error?.data?.message)
    }
  },[isError, isAuthenticated])
  

  const handleChange=(e)=>{
    setValues({...values,[e.target.name]: e.target.value})
  }

  const handleSubmit=(e)=>{
        e.preventDefault()
        
       
          const data={
            email: email,
            password: password
          }
       
          login(data)
          
        
  }


  useEffect(()=>{
    if(isSuccess){
      toast.success(data.message)
    }
  },[isSuccess])

if(isLoading) return <Loader/>
  
  return (
   <Fragment>
    <MetaData title={"login"}/>
     <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="breadcrumbs-content">
                        <h1 class="page-title">Login</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <ul class="breadcrumb-nav">
                        <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                        <li>Login</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <div class="account-login section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">


                  <LoginForm isLoading={isLoading}  handleChange={handleChange} values={values} handleSubmit={handleSubmit}/>

                </div>
                    
                </div>
                    
                </div>
                    
                </div>
   </Fragment>
  )
}

export default Loging
