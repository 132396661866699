import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Main from '../commponents/home/Main'
import Products from '../commponents/home/Products'
import ProductDetails from '../commponents/home/ProductDetails'
import Cart from '../commponents/cart/Cart'
import Payment from '../commponents/cart/Payment'
import Loging from '../commponents/auth/Loging'
import Register from '../commponents/auth/Register'
import ActivateAccount from '../commponents/user/ActivateAccount'
import ProtectedRoutes from '../commponents/user/ProtectedRoutes'
import UserCheckout from '../commponents/cart/UserCheckout'
import Shipping from '../commponents/cart/Shipping'
import OrderSuccess from '../commponents/order/OrderSuccess'
import Orders from '../commponents/order/Orders'
import OrderDetails from '../commponents/order/OrderDetails'
import Invoice from '../commponents/invoice/Invoice'
import Profile from '../commponents/user/Profile'
import Contact from '../commponents/home/Contact'
import AboutUs from '../commponents/home/AboutUs'
import Policy from '../commponents/home/Policy'
import ForgotPassword from '../commponents/user/ForgotPassword'
import ResetPassword from '../commponents/user/ResetPassword'
const UserRoutes = () => {
  return (
    <Routes>
        <Route exact path='/' element={<Products/>}/>
        <Route exact path='/home' element={<Main/>} />
        <Route exact path='/contact' element={<Contact/>} />
        <Route exact path='/about' element={<AboutUs/>} />
        <Route exact path='/login' element={<Loging/>} />
        <Route exact path='/policy' element={<Policy/>} />
        <Route exact path='/account-password-recovery' element={<ForgotPassword/>} />
        <Route exact path='/reset/:resetToken' element={<ResetPassword/>} />
        <Route path='/activate-user-account/:userId' element={<ActivateAccount/>}/>
        <Route exact path='/register' element={<Register/>} />
       <Route path='/product/:productId' element={<ProductDetails/>}/>
       <Route path='/cart' element={<Cart/>}/>
       <Route path='/checkout' element={<ProtectedRoutes><UserCheckout/></ProtectedRoutes>}/>
       <Route path='/shipping' element={<ProtectedRoutes><Shipping/></ProtectedRoutes>}/>
       <Route path='/payment' element={<ProtectedRoutes><Payment/></ProtectedRoutes>}/>
       <Route path='/success/:paymentId' element={<ProtectedRoutes><OrderSuccess/></ProtectedRoutes>}/>
       <Route path='/my-orders' element={<ProtectedRoutes><Orders/></ProtectedRoutes>}/>
       <Route path='/order/:orderId' element={<ProtectedRoutes><OrderDetails/></ProtectedRoutes>}/>
       <Route path='/invoice/:orderId' element={<ProtectedRoutes><Invoice/></ProtectedRoutes>}/>
       <Route path='/profile' element={<ProtectedRoutes><Profile/></ProtectedRoutes>}/>
    </Routes>
  )
}

export default UserRoutes
