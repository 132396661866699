
import Header from './commponents/layouts/Header';
import Footer from './commponents/layouts/Footer';
import UserRoutes from './helpers/UserRoutes';
import AdminRoutes from './helpers/AdminRoutes';
import { ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useProfileQuery } from './store/api/userApi';

function App() {
  const {data}=useProfileQuery()
  useEffect(()=>{
    function getProfile(){
      return data
    }

    getProfile()
  },[])
  return (
    <>
      <Header/>
      <ToastContainer position='top-center'/>

     <AdminRoutes/>
     <UserRoutes/>
     
      <Footer/>
    </>
  );
}

export default App;
