import { createSlice } from "@reduxjs/toolkit";



const userSlice=createSlice({
    name:"userSlice",
    initialState:{
      user: null,
      isAuthenticated: false,
      loading:true,
    },
    
    reducers:{
        SetUser:(state,action)=>{
       
            
              state.user=action.payload
            
        },
       

        SetAuthenticated:(state,action)=>{
          state.isAuthenticated=action.payload
          
        },
        setLoading:(state,action)=>{
          state.loading=action.payload
        },
    
        DELETE_ACCOUNT:(state,action)=>{
         const {payload}=action;
          state.user= Array.from(state.user).filter(x=>x.id!==payload.userId)
          
        },

     


    }
})

export const {SetUser,SetAuthenticated,DELETE_ACCOUNT, setLoading}=userSlice.actions;
export default userSlice.reducer;