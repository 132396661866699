import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLazyCheckSalesByDatesQuery } from '../../../store/api/orderApi';
import {toast} from 'react-toastify'
import Loader from '../../../utils/Loader';
import SalesChart from '../chart/SalesChart';
const SideBar = ({children}) => {
  const [startDate, setStartDate] = useState(new Date().setDate(1));
  const [endDate, setEndDate] = useState(new Date());
  
  const [checkSalesByDates,{data,isError,isLoading, error}]=useLazyCheckSalesByDatesQuery()






const submitHandler=()=>{
  checkSalesByDates({
    startDate: new Date(startDate).toISOString(),
    endDate: new Date(endDate).toISOString()
  })


}


useEffect(()=>{
  if(isError){
      toast.error(error?.data.message)
  }
  if(startDate && endDate && !data){
    checkSalesByDates({
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString()
    })

  
  
   }
 
},[isError])


 if(isLoading) return <Loader/>
  return (
   
  
    <section className="product-grids section">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-12">
             
                <div className="product-sidebar">
             
                    
                 
                    <div className="single-widget">
                        <h3><Link to="/dashboard">Dashboard</Link></h3>
                        <ul className="list">
                        <li>
                                <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                            </li>
                            <li>
                                    <Link to="/admin/company"><i className="fas fa-house-user"></i>Company</Link>
                                </li>
                            <li>
                                <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                            </li>
                            <li>
                            <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                            </li>
                            <li>
                            <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                            </li>
                            <li>
                            <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                            
                        </ul>
                    </div>
              
                 
                  
                    
                </div>
               
            </div>



            <div className="col-lg-9 col-12">
                <div className="product-grids-head">



                    <div className="product-grid-topbar">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-8 col-12">
                                <div className="product-sorting">
                                   <div className='row'>

          <div className='col-md-4'>
          <label htmlFor='from'>From:</label>
                                    <DatePicker 
        selected={startDate}
         onChange={(date) => setStartDate(date)}
         selectsStart
         startDate={startDate}
         endDate={endDate}
         className='form-control'
         />

          </div>
       <div className='col-md-4'>
       <label htmlFor='from'>To:</label>  <DatePicker 
        selected={endDate}
         onChange={(date) => setEndDate(date)}
         selectsEnd
         startDate={startDate}
         endDate={endDate}
         minDate={startDate}
         className='form-control'
         />

       </div>

                                   </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-4 col-12">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button type='button' className="btn btn-primary fetch-btn ms-4 mt-3 px-5" onClick={submitHandler}>Fetch</button>
                                        
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>


                    <div className="tab-content" id="nav-tabContent">

                        <div className="tab-pane fade show active" id="nav-grid" role="tabpanel"
                            aria-labelledby="nav-grid-tab">











    <div className="row">
   <div classNameName='col-md-6'>
   <div className="single-widget range">
        <div className="card info-card sales-card">

          <div className="filter">
            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <li className="dropdown-header text-start">
                <h6>Filter</h6>
              </li>

              <li><a className="dropdown-item" href="#">Today</a></li>
              <li><a className="dropdown-item" href="#">This Month</a></li>
              <li><a className="dropdown-item" href="#">This Year</a></li>
            </ul>
          </div>

          <div className="card-body">
            <h5 className="card-title">Total Sales </h5>

            <div className="d-flex align-items-center">
              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                <i className="bi bi-cart"></i>
              </div>
              <div className="ps-3">
                <h6>${data?.totalSales}</h6>
                

              </div>
            </div>
          </div>

        </div>
      </div>
   </div>

  
     <div classNameName='col-md-6'>
     <div className="single-widget range">
        <div className="card info-card revenue-card">

          <div className="filter">
            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <li className="dropdown-header text-start">
                <h6>Filter</h6>
              </li>

              <li><a className="dropdown-item" href="#">Today</a></li>
              <li><a className="dropdown-item" href="#">This Month</a></li>
              <li><a className="dropdown-item" href="#">This Year</a></li>
            </ul>
          </div>

          <div className="card-body">
            <h5 className="card-title"><span>Total Orders</span></h5>

            <div className="d-flex align-items-center">
              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                <i className="bi bi-currency-dollar"></i>
              </div>
              <div className="ps-3">
                <h6>{data?.totalNumOfOrders}</h6>
               

              </div>
            </div>
          </div>

        </div>
      </div>
      </div>

    </div>





<SalesChart startDate={startDate} endDate={endDate} sales={data?.salesData}/>






</div>



</div>

</div>
</div>
</div>
</div>
</section>
  )
}

export default SideBar
