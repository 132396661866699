import React from 'react'



const EditCompanyForm = ({values,handleChange, companyId,
    handleSubmit,handleImageChange,preview}) => {
    const {name,phone,email,address,state,zipCode,city,policy, description}=values||{}


  return (
    <section className="contact-us section">
   <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 className="title" >{companyId?"Edit Company":'Add Company'}</h6>
                                <section className="checkout-steps-form-content">

                                <nav>
      <ol className="breadcrumb">
        
      <div className='row'>

      <h5 className="card-title">
              {preview?<img
              src={preview}
              alt="preview_image"
              className="img img-fluid m-2"
              style={{width:"45px",height:"55px"}}
            />:''}
              </h5>
  
  </div>
         
      </ol>
    </nav>
                                            <form onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Name</label>
                                                <div className="form-input form">
                                                    <input type="text" name='name' defaultValue={name} onChange={handleChange} placeholder="company Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Phone</label>
                                                <div className="form-input form">
                                                    <input type="text" name='phone' defaultValue={phone} onChange={handleChange} placeholder="company phone"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Email</label>
                                                <div className="form-input form">
                                                   <input name='email' defaultValue={email} className='form-control' onChange={handleChange}  placeholder="company email"/>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Address</label>
                                                <div className="form-input form">
                                                    <input type="text" name='address' defaultValue={address} onChange={handleChange} placeholder="address"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>State</label>
                                                <div className="form-input form">
                                                    <input type="text" name='state' defaultValue={state} onChange={handleChange} placeholder="enter state"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Zip Code</label>
                                                <div className="form-input form">
                                                    <input type="text" name='zipCode' defaultValue={zipCode} onChange={handleChange} placeholder="zip code"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>City</label>
                                                <div className="form-input form">
                                                    <input type="text" name='city' defaultValue={city} onChange={handleChange} placeholder="city"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="single-form form-default">
                                                <label>Description</label>
                                             
                                                <textarea className='form-control' name="description" defaultValue={description} onChange={handleChange} cols="60" rows="8" id="description" ></textarea>
                                            
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="single-form form-default">
                                                <label>Policy</label>
                                              
                                                <textarea className='form-control' name="policy" defaultValue={policy} onChange={handleChange} cols="60" rows="8" id="policy" ></textarea>
                                        
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Logo</label>
                                                <div className="form-input form">
                                                    <input name="logo"  type="file" id="logo" onChange={handleImageChange} />
                                                </div>
                                            </div>
                                        </div>

                                       
                                    
                                        <div className="col-md-12">
                                            <div className="single-form button">
                                                <button className="btn" type='submit'
                                                   >{companyId?"Edit Company":'Add Company'}</button>
                                            </div>
                                        </div>
                                       
                                    </div>

                                    </form>
                                </section>
                            </li>
                            
                           
                        </ul>
                    </div>
                </div>
               
            </div>
      
         
        </div>
    </section>
    
  )
}

export default EditCompanyForm