import React from 'react'
import {Link} from 'react-router-dom'
const LoginForm = ({handleChange,handleSubmit, values,isLoading}) => {
  const {email, password}=values||''
  return (
    <div>
       <form class="card login-form"  onSubmit={handleSubmit}>
                        <div class="card-body">
                            <div class="title">
                                <h3>Login Now</h3>
                                {/* <p>You can login using your social media account or email address.</p> */}
                            </div>
                            {/* <div class="social-login">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12"><a class="btn facebook-btn"
                                            href="javascript:void(0)"><i class="lni lni-facebook-filled"></i> Facebook
                                            login</a></div>
                                    <div class="col-lg-4 col-md-4 col-12"><a class="btn twitter-btn"
                                            href="javascript:void(0)"><i class="lni lni-twitter-original"></i> Twitter
                                            login</a></div>
                                    <div class="col-lg-4 col-md-4 col-12"><a class="btn google-btn"
                                            href="javascript:void(0)"><i class="lni lni-google"></i> Google login</a>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div class="alt-option">
                                <span>Or</span>
                            </div> */}
                            <div class="form-group input-group">
                                <label htmlFor="email">Email</label>
                                <input class="form-control" name="email" value={email} onChange={handleChange} type="email" id="email" />
                            </div>
                            <div class="form-group input-group">
                                <label htmlFor='password'>Password</label>
                                <input class="form-control" name='password' value={password} onChange={handleChange} type="password" id="password"/>
                            </div>
                            <div class="d-flex flex-wrap justify-content-between bottom-content">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input width-auto" id="exampleCheck1"/>
                                    <label class="form-check-label">Remember me</label>
                                </div>
                                <Link class="lost-pass" to="/account-password-recovery">Forgot password?</Link>
                            </div>
                            <div class="button">
                                <button disabled={isLoading} class="btn" type="submit">{isLoading?"Loading...":"Login"}</button>
                            </div>
                            <p class="outer-link">Don't have an account? <Link to="/register">Register here </Link>
                            </p>
                        </div>
                    </form>
      
    </div>
  )
}

export default LoginForm
