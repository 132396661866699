import PersonnalForm from '../form/PersonnalForm'
import React, { Fragment, useState,useEffect} from 'react'
import {toast} from 'react-toastify'
import Loader from '../../utils/Loader'
import {useUpdateUserProfileMutation } from '../../store/api/userApi'
import { Link } from 'react-router-dom'
import {UsaStates} from 'usa-states'
import { useSelector } from 'react-redux'
import UpdateUserForm from '../form/UserUpdateForm'

const Profile = () => {
    var usStates = new UsaStates();
    const states=Object.values(usStates.states)
    const {user:userDetails}=useSelector(state=>state.auth)
  
     const [values,setValues]=useState({email:'',name:'',phoneNumber:'',address:'',city:'',zipCode:'',state:''})
    
 
     const {email,name,phoneNumber,address,city,zipCode,state}=values
     const [ updateUserProfile,{isError,isLoading,isSuccess,error,data}]=useUpdateUserProfileMutation()

     useEffect(()=>{
        const loadUser=()=>{
         try {
             setValues({...values, ...userDetails});
 
         } catch (error) {
             console.log(error)
         }
        }
   loadUser()
     console.log(userDetails)
     },[userDetails])
 
    
   
 
 
       
         const handleChange = (e) => {
           setValues({ ...values, [e.target.name]: e.target.value }); 
          
           
         };
        
        
         
 
     const handleSubmit =  (e) => {
       e.preventDefault();
    
     const   formData={
         email,name,phoneNumber,address,city,zipCode,state
       
     }
       
   
      try {
         updateUserProfile(formData)
      
       
       
      } catch (er) {
        console.log(er)
      }
   
     }
 
 
     useEffect(()=>{
        if(isSuccess){
         toast.success(data?.message)
         setTimeout(() => {
           window.location.reload();
         }, 2000);
        }
     },[isSuccess])
 
 
     useEffect(()=>{
       if(isError){
        toast.error(error?.data?.message)
        
       }
    },[isError])
 
 
   if(isLoading) return <Loader/>

  return (
    <Fragment>
     <div className="breadcrumbs">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="breadcrumbs-content">
                        <h1 className="page-title">Registration</h1>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <ul className="breadcrumb-nav">
                        <li><Link to="/"><i className="lni lni-home"></i> Home</Link></li>
                        <li>Profile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <div className="account-login section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="register-form">
                        <div className="title">
                            <h3>Edit Profile</h3>
                            <h4>Name: {userDetails?.name}</h4>
                        </div>

     <UpdateUserForm list={states} values={values} handleChange={handleChange} handleSubmit={handleSubmit}/>


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
   </Fragment>
  )
}

export default Profile