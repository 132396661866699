import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'

import { Link, useParams } from 'react-router-dom'

import {toast} from 'react-toastify'
import Loader from '../../../utils/Loader'

import { useGetProductDetailQuery, useUpdateProductMutation } from '../../../store/api/productApi'
import UpdateProductForm from '../form/UpdateProductForm'

const EditProduct = () => {
   const params=useParams()
   const {productId}=params

   const {data:productDetails}=useGetProductDetailQuery(productId)
 
    const [values,setValues]=useState({name:'',description:'',
    category:'',price:'',quantity:'',discount:'',colors:''})
   

    const {name,description,category,price,quantity,discount,colors}=values
    const [updateProduct,{isError,isLoading,isSuccess,error,data}]=useUpdateProductMutation()



   



      useEffect(()=>{
        setValues({...values, ...productDetails});

  
    
    },[productDetails])

   
  


      
        const handleChange = (e) => {
          setValues({ ...values, [e.target.name]: e.target.value }); 
         
          
        };
       
       
        

    const handleSubmit =  (e) => {
      e.preventDefault();
   
    const   formData={
        colors,
       name, 
       description,
      price,
      quantity,
      discount,
      category
      
    }
      
  
     try {
        updateProduct({id: productId,body: formData})
     
      
      
     } catch (er) {
       console.log(er)
     }
  
    }


    useEffect(()=>{
       if(isSuccess){
        toast.success(data?.message)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
       }
    },[isSuccess])


    useEffect(()=>{
      if(isError){
       toast.error(error?.data?.message)
       
      }
   },[isError])


  if(isLoading) return <Loader/>
  return (
  <Fragment>
<MetaData title={"edit product"}/>
<section className="product-grids section">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-12">
             
                <div className="product-sidebar">
             
                    
                 
                    <div className="single-widget">
                        <h3><Link to="/dashboard">Dashboard</Link></h3>
                        <ul className="list">
                        <li>
                                <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                            </li>
                            <li>
                                <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                            </li>
                            <li>
                            <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                            </li>
                            <li>
                            <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                            </li>
                            <li>
                            <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                        </ul>
                    </div>
              
                 
                  
                    
                </div>
               
            </div>


            <div className="col-lg-9 col-12">
                <div className="product-grids-head">
            <div className="tab-content" id="nav-tabContent">

<div className="tab-pane fade show active" id="nav-grid" role="tabpanel"
    aria-labelledby="nav-grid-tab">

<section className="contact-us section">
   <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 className="title" >Edit Product</h6>
                                <section className="checkout-steps-form-content">


    <UpdateProductForm 
   
    values={values} 
    handleChange={handleChange} 

     handleSubmit={handleSubmit} 
 
  />



                                    
</section>
                            </li>
                            
                           
                        </ul>
                    </div>
                </div>
               
            </div>
      
         
        </div>
    </section>


    

</div>



</div>

</div>
</div>
</div>
</div>
</section>
  </Fragment>
  )
}

export default EditProduct