export const getDiscountPrice=(price,discount)=>{
  let getPrice=0
let text=''
 if(discount!==0){
    getPrice=price-price*(discount/100);
    text=  <h3 class="price">${getPrice.toFixed(2)}<span>${price.toFixed(2)}</span></h3>
 }else{


text=  <span><strong>${price.toFixed(2)}</strong></span>
 }




 return text;



}

let tax=0
let shipping_cost=0

export const get_total_price=(cartSubtotal)=>{
    let total=0
tax=0.04875
    if(cartSubtotal>=20){
      total=(cartSubtotal+(cartSubtotal*tax)).toFixed(2)
    }else if(cartSubtotal!==0 && cartSubtotal<20){
        shipping_cost=5
        total=(cartSubtotal+((cartSubtotal*tax)+shipping_cost)).toFixed(2)	
    }

    return total
}

export const shipping_price=(cartSubtotal)=>{
  
   if(cartSubtotal>100){
    shipping_cost=0
   }else if(cartSubtotal!==0 && cartSubtotal<20){
    shipping_cost=5
   }

   return shipping_cost
}


export const get_tax_price=(cartSubtotal)=>{
    let total=0
    tax=0.04875
    total= (cartSubtotal*tax).toFixed(2)

    return total
}
