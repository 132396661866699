import React, { Fragment } from 'react'
import OrderItems from './OrderItems'
import { Link } from 'react-router-dom'
const Orders = () => {
  return (

    <Fragment>
          <section class="product-grids section">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12">
                 
                    <div class="product-sidebar">
                 
                        
                     
                        <div class="single-widget">
                            <h3><Link to="/dashboard">Dashboard</Link></h3>
                            <ul class="list">
                            <li>
                                    <Link to="/dashboard"><i class="fas fa-salesforce"></i> Sales </Link>
                                </li>
                                <li>
                                    <Link to="/admin/products"><i class="fas fa-list"></i> Products </Link>
                                </li>
                                <li>
                                <Link to="/admin/category"><i class="fas fa-list"></i> Categories </Link>
                                </li>
                                <li>
                                <Link to="/admin/users"><i class="fas fa-users"></i> Users </Link>
                                </li>
                                <li>
                                <Link to="/admin/orders"><i class="fas fa-list-alt"></i> Orders </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-product"><i class="fas fa-plus-circle"></i> Add Product </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-category"><i class="fas fa-plus-circle"></i> Add Category </Link>
                                </li>

                                <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                                
                            </ul>
                        </div>
                  
                     
                      
                        
                    </div>
                   
                </div>
    
    
                <div class="col-lg-9 col-12">
                    <div class="product-grids-head">
                <div class="tab-content" >
<OrderItems/>






</div>

</div>
</div>
</div>
</div>
</section>  
    </Fragment>

  )
}

export default Orders
