import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'
import {MDBDataTable} from 'mdbreact'
import {toast} from 'react-toastify'
import { Link ,useNavigate} from 'react-router-dom'
import Loader from '../../../utils/Loader'
import { useDeleteUserMutation, useGetAllUsersQuery } from '../../../store/api/userApi'

const Users = () => {
const {isError,isLoading,data,error}=useGetAllUsersQuery()
const [  deleteUser,{isError: Error}]=useDeleteUserMutation()

const navigation=useNavigate()
useEffect(()=>{
    if(isError){
        toast.error(error?.data.message)
    }
   
},[isError])

const deleteUserHandler=(id)=>{
    deleteUser({id: id})
}
const editUserHandler=(id)=>{
    navigation(`/edit-user/${id}`)
}


const setUsers=()=>{
    const users={
       columns:[
           {
           label:'Name',
           field:'name',
           sort:'asc'
       },
       {
           label:'Phone',
           field:'phone'
       },
       {
        label:'Email',
        field:'email'
    },
  
       
       {
           label:'Actions',
           field:'actions',
           sort:'asc'
       }

   ],
       rows:[]

    }
    data?.forEach((item)=>{
       users.rows.push({
           name: item?.name,
           phone: item?.phoneNumber,
           email: item?.email,
           actions:<><button onClick={()=>deleteUserHandler(item?._id)} className='btn btn-primary'><i className='fa fa-trash'></i></button> <button onClick={()=>editUserHandler(item?._id)} className='btn btn-info'><i className='fa fa-edit'></i></button>
           </>

       })
    })
    return users
   }



    if(isLoading) return <Loader/>
  return (
    <Fragment>
    <MetaData title={"users"}/>
    <section class="product-grids section">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12">
                 
                    <div class="product-sidebar">
                 
                        
                     
                        <div class="single-widget">
                            <h3><Link to="/dashboard">Dashboard</Link></h3>
                            <ul class="list">
                            <li>
                                    <Link to="/dashboard"><i class="fas fa-salesforce"></i> Sales </Link>
                                </li>
                                <li>
                                    <Link to="/admin/products"><i class="fas fa-list"></i> Products </Link>
                                </li>
                                <li>
                                <Link to="/admin/category"><i class="fas fa-list"></i> Categories </Link>
                                </li>
                                <li>
                                <Link to="/admin/users"><i class="fas fa-users"></i> Users </Link>
                                </li>
                                <li>
                                <Link to="/admin/orders"><i class="fas fa-list-alt"></i> Orders </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-product"><i class="fas fa-plus-circle"></i> Add Product </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-category"><i class="fas fa-plus-circle"></i> Add Category </Link>
                                </li>
                                <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                            </ul>
                        </div>
                  
                     
                      
                        
                    </div>
                   
                </div>
    
    
                <div class="col-lg-9 col-12">
                    <div class="product-grids-head">
                <div class="tab-content" >
    
   


<MDBDataTable

data={setUsers()}
className="py-3"
noBottomColumns={true}
bordered
striped
hover
responsive
paging={true}
disableRetreatAfterSorting={true}

/>






</div>

</div>
</div>
</div>
</div>
</section>    
</Fragment>
  )
}

export default Users
