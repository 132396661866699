import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../commponents/admin/home/Home'
import NewCategory from '../commponents/admin/categories/NewCategory'
import Category from '../commponents/admin/categories/Category'
import NewProduct from '../commponents/admin/products/NewProduct'
import Products from '../commponents/admin/products/Products'
import ProtectedRoutes from '../commponents/admin/ProtectedRoutes'
import Orders from '../commponents/admin/orders/Orders'
import ViewOrders from '../commponents/admin/orders/ViewOrders'
import Invoice from '../commponents/admin/invoice/Invoice'
import UpdateOrder from '../commponents/admin/orders/UpdateOrder'
import AddCompany from '../commponents/admin/company/AddCompany'
import Company from '../commponents/admin/company/Company'
import EditCompany from '../commponents/admin/company/EditCompany'
import EditCategory from '../commponents/admin/categories/EditCategory'
import EditProduct from '../commponents/admin/products/EditProduct'
import EditImage from '../commponents/admin/products/EditImage'
import Users from '../commponents/admin/users/Users'
import EditUser from '../commponents/admin/users/EditUser'
const AdminRoutes = () => {
  return (
    <Routes>

        <Route path='/dashboard' element={<ProtectedRoutes><Home/></ProtectedRoutes>} />
        <Route path='/admin/add-category' element={<ProtectedRoutes><NewCategory/></ProtectedRoutes>} />
        <Route path='/admin/category' element={<ProtectedRoutes><Category/></ProtectedRoutes>} />
        <Route path='/admin/add-product' element={<ProtectedRoutes><NewProduct/></ProtectedRoutes>} />
        <Route path='/admin/products' element={<ProtectedRoutes><Products/></ProtectedRoutes>} />
        <Route path='/admin/orders' element={<ProtectedRoutes><Orders/></ProtectedRoutes>} />
        <Route path='/admin/view-orders/:orderId' element={<ProtectedRoutes><ViewOrders/></ProtectedRoutes>} />
        <Route path='/admin/invoice/:orderId' element={<ProtectedRoutes><Invoice/></ProtectedRoutes>} />
        <Route path='/admin/edit-order/:orderId' element={<ProtectedRoutes><UpdateOrder/></ProtectedRoutes>} />
        <Route path='/admin/add-company' element={<ProtectedRoutes><AddCompany/></ProtectedRoutes>} />
        <Route path='/admin/company' element={<ProtectedRoutes><Company/></ProtectedRoutes>} />
        <Route path='/admin/edit-company/:companyId' element={<ProtectedRoutes><EditCompany/></ProtectedRoutes>} />
        <Route path='/edit-category/:categoryId' element={<ProtectedRoutes><EditCategory/></ProtectedRoutes>} />
        <Route path='/edit-product/:productId' element={<ProtectedRoutes><EditProduct/></ProtectedRoutes>} />
        <Route path='/edit-images/:productId' element={<ProtectedRoutes><EditImage/></ProtectedRoutes>} />
        <Route path='/admin/users' element={<ProtectedRoutes><Users/></ProtectedRoutes>} />
        <Route path='/edit-user/:userId' element={<ProtectedRoutes><EditUser/></ProtectedRoutes>} />
    </Routes>
  )
}

export default AdminRoutes
