import React, { Fragment,useEffect} from 'react'
import CreditCardForm from '../form/CreditCardForm'
import Loader from '../../utils/Loader'
import Checkout from './Checkout'
import CheckoutStep from './CheckoutStep'
import { useStripe,useElements,CardNumberElement,CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js'
import {useSelector} from  'react-redux'
import {toast} from 'react-toastify'
import { useCreateOrderMutation, useCreatePaymentMutation} from '../../store/api/orderApi'
import { get_tax_price,shipping_price,get_total_price } from '../../utils/result'
const PaymentComponent = () => {


  const [createOrder,{isLoading,isError,isSuccess,error,data}]=useCreateOrderMutation()

  const [createPayment,{isLoading:loading, data: clientSecret}]=useCreatePaymentMutation()
  const {user}=useSelector(state=>state.auth)
  const stripe=useStripe()
  const elements=useElements()
  const {cartItems,shippingInfo,cartSubtotal}=useSelector(state=>state.cart)
  const taxPrice=get_tax_price(cartSubtotal)
  const shippingPrice=shipping_price(cartSubtotal)
  const totalPrice=get_total_price(cartSubtotal)


 const options = {
    style: {
        base: {
            fontSize: '16px'
        },
        invalid: {
            color: '#9e2146'
        }
    }
}



const shipping={
         address: user?.address,
         city: user?.city,
         zipCode: user?.zipCode,
         state: user?.state,

}




const paymentData={
amount: Math.round(totalPrice*100)
}

useEffect(()=>{
    function getPayment(){
        createPayment(paymentData)
    }
    getPayment()
},[])
const paymentHandler=async(e)=>{
 e.preventDefault()
document.querySelector('#btn_pay').disabled=true

try {




if(!stripe || !elements){
return
}

const result= await stripe.confirmCardPayment(clientSecret,{
payment_method:{
  card: elements.getElement(CardNumberElement),
  billing_details:{
    address: {
      city: shippingInfo?.city,
      country: "US",
      line1: shippingInfo?.address,
      line2: null,
      postal_code: shippingInfo?.zipCode,
      state: shippingInfo?.state,
    },
    email: shippingInfo?.email,
    name: shippingInfo?.name,
    phone: shippingInfo?.phone

}
}
})
 if(result.error){
toast.error(result.error.message)
return
}else{

if(result.paymentIntent.status==='succeeded'){

toast.success("Your payment is success")
const paymentInfo = {
  id: result.paymentIntent.id,
  status: result.paymentIntent.status
}

let order = {
    orderItems:cartItems,
    itemsPrice: cartSubtotal,
    taxAmount:taxPrice,
    shippingAmount:shippingPrice,
    totalAmount: totalPrice,
    shippingInfo: shipping,
    paymentInfo: paymentInfo
  
  
  }

if(createOrder(order)){
    
localStorage.removeItem('cartItems')
localStorage.removeItem('shippingInfo')
window.location.reload()
setTimeout(() => {

  window.location.href=`/#/success/${result.paymentIntent.id}`
}, 2000);
}else{
    toast.error(error?.data?.message)
}
}else{
toast.error("There is some issue while payment processing")
}
}


} catch (error) {

document.querySelector('#btn_pay').disabled=false
toast.error(error.message)

}
}

useEffect(()=>{
    if(isSuccess){
      toast.success(data.message)
    }
  },[isSuccess])

useEffect(()=>{
    if(isError){
      toast.error(error?.data?.message)
    }
  },[isError])
  
  if(isLoading) return <Loader/>

  return (
    <Fragment>
     
        <Checkout
        
        step={<CheckoutStep shipping={"shipping"} checkout={"checkout"} payment={"payment"}/>}
        children={
          <CreditCardForm loading={loading} paymentHandler={paymentHandler} options={options}
         CardCvcElement={CardCvcElement} CardExpiryElement={CardExpiryElement}
          CardNumberElement={CardNumberElement}/>
       }
        
        
        />

  
    </Fragment>
  )
}

export default PaymentComponent
