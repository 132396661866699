import React, { Fragment} from 'react'

import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js'

import {useGetStripeApiKeyQuery} from '../../store/api/orderApi'

import PaymentComponent from './PaymentComponent';
const Payment = () => {


  
  const {data}=useGetStripeApiKeyQuery()

 









  return (
    <Fragment>
      <Elements stripe={loadStripe(data)}>
       <PaymentComponent/>
 </Elements>
  
    </Fragment>
  )
}

export default Payment
