import { createSlice } from "@reduxjs/toolkit";



const productSlice=createSlice({
    name:"productSlice",
    initialState:{
      products: null,
      loading:true
    },
    
    reducers:{
        SetProduct:(state,action)=>{
       
            
              state.products=action.payload
            
        },
       

       
        setLoading:(state,action)=>{
          state.loading=action.payload
        },
        DELETE_IMAGE_PATH:(state,action)=>{
          const {payload}=action
          return{
           ...state,
           products:{...state.products.filter(x=>x.id!==payload.productId)}
          }
       },
    
        DELETE_PRODUCT:(state,action)=>{
         const {payload}=action;
          state.products= Array.from(state.products).filter(x=>x.id!==payload.productId)
          
        }


    }
})

export const {SetProduct,DELETE_PRODUCT, setLoading,DELETE_IMAGE_PATH}=productSlice.actions;
export default productSlice.reducer;