import React,{useState,useEffect, Fragment} from 'react'

import MetaData from '../../utils/MetaData'
import {toast} from 'react-toastify'
import Loader from '../../utils/Loader'
import { useForgotPasswordMutation } from '../../store/api/userApi'
import ForgotPasswordForm from '../form/ForgotPasswordForm'
import { Link } from 'react-router-dom'
const ForgotPassword = () => {


    const [values,setValues]=useState({email:''})
    const {email}=values
    const [forgotPassword,{isLoading, isError,data,isSuccess,error}]=useForgotPasswordMutation()
   
  

    
const handleSubmit=async(e)=>{
   e.preventDefault();
   const userData={
   
     email: email,
  
     
 
    }
   try {
  
    forgotPassword(userData)
  
    
     
   } catch (error) {
    toast.error(error?.data?.message)
   }
  }
  const handleChange=(e)=>{
   setValues({ ...values, [e.target.name]: e.target.value });
  }

  useEffect(()=>{
    if(isError){
        toast.error(error?.data?.message)
    }
   
},[isError])


useEffect(()=>{
  if(isSuccess){
      toast.success(data?.message)
  }
 
},[isSuccess])




    if(isLoading) return <Loader/>

  return (
    <Fragment>
          <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="breadcrumbs-content">
                        <h1 class="page-title">Forgot Password</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <ul class="breadcrumb-nav">
                        <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                        <li>Forgot Password</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <div class="account-login section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
        <MetaData title={"reset"}/>
        <ForgotPasswordForm values={values} isLoading={isLoading} handleChange={handleChange} handleSubmit={handleSubmit}/>

        </div>
                    
                    </div>
                        
                    </div>
                        
                    </div>
    </Fragment>
  )

}

export default ForgotPassword