import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'

import {toast} from 'react-toastify'
import { Link ,useNavigate} from 'react-router-dom'
import Loader from '../../../utils/Loader'
import { useParams } from 'react-router-dom'

import { useCategoryDetailsQuery, useUpdateCategoryMutation } from '../../../store/api/categoryApi'
import EditCategoryForm from '../form/EditCategoryForm'


const EditCategory = () => {

  const params=useParams();
  const {categoryId}=params
 
  const {data: categoryDetails} =useCategoryDetailsQuery(categoryId)


  const [values, setValues]=useState({image:'',name:'',description:''})
  const {name,image,description}=values


  useEffect(()=>{
    setValues({ ...values, ...categoryDetails});
    setPreview(`https://ervadinepetitdeboutique.com/${categoryDetails?.image}`);
},[categoryDetails])



const [preview, setPreview] = useState(
  "https://via.placeholder.com/100x100.png?text=PREVIEW"
);




  const [updateCategory,{isError,isLoading, data,isSuccess,error}]=useUpdateCategoryMutation()

  
const navigation=useNavigate()


const handleImageChange = (e) => {
  // console.log(e.target.files[0]);
  setPreview(URL.createObjectURL(e.target.files[0]));
  setValues({ ...values, logo: e.target.files[0] });
};

const handleChange = (e) => {
  setValues({ ...values, [e.target.name]: e.target.value });
};

const handleSubmit =  (e) => {
  e.preventDefault();
 

  let editData = new FormData();
  editData.append("name", name);
  editData.append("description", description);
  editData.append("image", image);




 try {
 updateCategory({id:categoryId, body: editData})
 setTimeout(()=>{window.location.href='/admin/category'},2000)

 } catch (error) {
   toast.error(error?.data?.message)
 }

}






useEffect(()=>{
    if(isError){
        toast.error(error?.data?.message)
    }
   
},[isError])


useEffect(()=>{
  if(isSuccess){
      toast.success(data?.message)
  }
 
},[isSuccess])




    if(isLoading) return <Loader/>
  return (
    <Fragment>
    <MetaData title={"company"}/>
    <section className="product-grids section">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-12">
                 
                    <div className="product-sidebar">
                 
                        
                     
                        <div className="single-widget">
                            <h3><Link to="/dashboard">Dashboard</Link></h3>
                            <ul className="list">
                            <li>
                                    <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                                </li>
                                <li>
                                    <Link to="/admin/company"><i className="fas fa-house-user"></i>Company</Link>
                                </li>
                                <li>
                                    <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                                </li>
                                <li>
                                <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                                </li>
                                <li>
                                <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                                </li>
                                <li>
                                <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                                </li>
                                <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                                
                            </ul>
                        </div>
                  
                     
                      
                        
                    </div>
                   
                </div>
    
    
                <div className="col-lg-9 col-12">
                    <div className="product-grids-head">
                <div className="tab-content" >
    
   




<EditCategoryForm values={values} preview={preview} handleChange={handleChange} 
handleImageChange={handleImageChange} handleSubmit={handleSubmit} />






</div>

</div>
</div>
</div>
</div>
</section>    
</Fragment>
  )
}

export default EditCategory
