import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'
import CategoryForm from '../form/CategoryForm'
import { Link } from 'react-router-dom'
import { useNewCategoryMutation } from '../../../store/api/categoryApi'
import {toast} from 'react-toastify'
import Loader from '../../../utils/Loader'
const NewCategory = () => {

  const [newCategory, {isLoading,isError,error,isSuccess,data}]=useNewCategoryMutation()
  const [values,setValues]=useState({name:'',description:'',image:''})

  const {name,description,image}=values

  const [preview, setPreview] = useState(
    "https://via.placeholder.com/100x100.png?text=PREVIEW"
  );
  const handleImageChange = (e) => {
      // console.log(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setValues({ ...values, image: e.target.files[0] });
    };
  
    const handleChange = (e) => {
      setValues({...values, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log(values);
      // console.log(location);
  
      let categoryData = new FormData();

      categoryData.append("name", name);
      categoryData.append("description", description);
      image && categoryData.append("image", image);
  
      
  
     try {
      newCategory(categoryData)
     
      
      
     } catch (er) {
       toast.error(error?.data.message)
     }
  
    }


    useEffect(()=>{
       if(isSuccess){
        toast.success(data?.message)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
       }
    },[isSuccess])


    useEffect(()=>{
      if(isError){
       toast.error(error?.data?.message)
       
      }
   },[isError])


  if(isLoading) return <Loader/>
  return (
  <Fragment>
<MetaData title={"new category"}/>
<section class="product-grids section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-12">
             
                <div class="product-sidebar">
             
                    
                 
                    <div class="single-widget">
                        <h3><Link to="/dashboard">Dashboard</Link></h3>
                        <ul class="list">
                        <li>
                                <Link to="/dashboard"><i class="fas fa-salesforce"></i> Sales </Link>
                            </li>
                            <li>
                                <Link to="/admin/products"><i class="fas fa-list"></i> Products </Link>
                            </li>
                            <li>
                            <Link to="/admin/category"><i class="fas fa-list"></i> Categories </Link>
                            </li>
                            <li>
                            <Link to="/admin/users"><i class="fas fa-users"></i> Users </Link>
                            </li>
                            <li>
                            <Link to="/admin/orders"><i class="fas fa-list-alt"></i> Orders </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-product"><i class="fas fa-plus-circle"></i> Add Product </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-category"><i class="fas fa-plus-circle"></i> Add Category </Link>
                            </li>

                            <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                            
                        </ul>
                    </div>
              
                 
                  
                    
                </div>
               
            </div>


            <div class="col-lg-9 col-12">
                <div class="product-grids-head">
            <div class="tab-content" id="nav-tabContent">

<div class="tab-pane fade show active" id="nav-grid" role="tabpanel"
    aria-labelledby="nav-grid-tab">


 
    <CategoryForm preview={preview} values={values} handleChange={handleChange} 
    handleImageChange={handleImageChange} handleSubmit={handleSubmit}/>





    

</div>



</div>

</div>
</div>
</div>
</div>
</section>
  </Fragment>
  )
}

export default NewCategory