import React,{useEffect, useState} from 'react'
import PersonnalForm from '../form/PersonnalForm'
import Checkout from './Checkout'
import {UsaStates} from 'usa-states'
import CheckoutStep from './CheckoutStep'
import Loader from '../../utils/Loader'
import {useUpdateUserProfileMutation } from '../../store/api/userApi'
import {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const UserCheckout = () => {
   const {user}=useSelector(state=>state.auth)
   const navigation=useNavigate()
    var usStates = new UsaStates();
    const states=Object.values(usStates.states)
    const [values, setValues]=useState({
      email:'',name:"",phoneNumber:'',address:'',city:"",zipCode:"",state:"", country:""
    })
    const {email,name,phoneNumber,address,city,zipCode, state, country}=values
    const [ updateUserProfile,{isError,isLoading,error, isSuccess}]=useUpdateUserProfileMutation()

    const handleChange=(e)=>{
      setValues({...values,[e.target.name]: e.target.value})
    }

    const handleSubmit=()=>{
        const savedata={
          email,
          name,
          phoneNumber,
          address,
          city,
          zipCode,
          state,
          country
        }

        updateUserProfile(savedata)
      
    }
   
    useEffect(()=>{
   if(isError){
    toast.error(error?.data?.message)
   }

   if(isSuccess){
    navigation('/shipping')
   }

   setValues({...values,...user})
    },[isError, isSuccess])

    if(isLoading) return <Loader/>
    
  return (
    <Checkout
    step={  <CheckoutStep checkout/>}
    children={<PersonnalForm values={values} handleChange={handleChange} handleSubmit={handleSubmit} list={states}/>}
    />
  )
}

export default UserCheckout