import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'
import { Link, useParams } from 'react-router-dom'
import {toast} from 'react-toastify'
import Loader from '../../../utils/Loader'
import { useGetProductDetailQuery, useUpdateImagesMutation,useDeleteImageProductMutation} from '../../../store/api/productApi'
import EditImageForm from '../form/EditImageForm'



const EditImage = () => {


   const params=useParams()
   const {productId}=params
   const [previewImages, setPreviewImages] = useState([]);
   const [imagesUrl,setImagesUrl]=useState([])
   const {data:productDetails}=useGetProductDetailQuery(productId)
   const [values,setValues]=useState({images:[]})
   const {images}=values
   const [updateImages,{isError,isLoading,isSuccess,error,data}]=useUpdateImagesMutation()
   const [deleteImageProduct,{isError:Error}]=useDeleteImageProductMutation()


   


    useEffect(()=>{
	
        const files = [], fileReaders = [];
        if(images.length){
          [...images].forEach(item=>{
      
              const fileReader = new FileReader();
              fileReaders.push(fileReader);
              fileReader.onload = function(e) {
                const { result } = e.target;
                if (result) {
                  files.push(result)
                }
                if (files.length===images.length) {
                setPreviewImages(files)
                }

                
              }
             
              fileReader.readAsDataURL(item);
          })
        }
      
      
      },[images])

    


    useEffect(()=>{
        
        loadProductDetails();
       
    },[productDetails])


    const loadProductDetails=()=>{
        try {
            setImagesUrl(productDetails.images)
        } catch (error) {
            console.log(error)
        }
    }
  

    const handleImagesChange = (e) => {
        let image_files=Array.from(e.target.files)
       
          setValues({ ...values, images: image_files });
        };
      
      
       
        const deleteImageUrlPath=async(imagePath,productID)=>{
 
            try {
          
          
               deleteImageProduct({id: productID,imagePath: imagePath})
          
           
            } catch (error) {
              console.log(error)
            }
            
           
         }
        

    const handleSubmit =  (e) => {
      e.preventDefault();
      const formData= new FormData()
      Array.from(images).forEach((image)=>{
        formData.append('images',image)
        })
        
   
      
      
      
  
     try {
        updateImages({id: productId,body: formData})
     
      
      
     } catch (er) {
       console.log(er)
     }
  
    }


    useEffect(()=>{
       if(isSuccess){
        toast.success(data?.message)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
       }
    },[isSuccess])


    useEffect(()=>{
      if(isError){
       toast.error(error?.data?.message)
       
      }
   },[isError])


  if(isLoading) return <Loader/>
  return (
  <Fragment>
<MetaData title={"edit product"}/>
<section className="product-grids section">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-12">
             
                <div className="product-sidebar">
             
                    
                 
                    <div className="single-widget">
                        <h3><Link to="/dashboard">Dashboard</Link></h3>
                        <ul className="list">
                        <li>
                                <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                            </li>
                            <li>
                                <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                            </li>
                            <li>
                            <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                            </li>
                            <li>
                            <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                            </li>
                            <li>
                            <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                        </ul>
                    </div>
              
                 
                  
                    
                </div>
               
            </div>


            <div className="col-lg-9 col-12">
                <div className="product-grids-head">
            <div className="tab-content" id="nav-tabContent">

<div className="tab-pane fade show active" id="nav-grid" role="tabpanel"
    aria-labelledby="nav-grid-tab">

<section className="contact-us section">
   <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 className="title" >Edit Product</h6>
                                <section className="checkout-steps-form-content">

                             
        
        <div className='row'>
  
        {previewImages.map((item,idx)=>(<div className='col-md-3' key={idx} ><img src={item}  alt="" style={{width:'45px', height:'45px'}}/></div>))}
        </div>
        <div className='row' >
        <div className='col-xs-6' >
        {
         imagesUrl?.map((item,idx)=>(<img key={idx} src={`https://ervadinepetitdeboutique.com/${item.path}`}  alt="" style={{width:'20%', height:'35%'}}/>))}
  
        {
        
        productDetails &&   productDetails.images.length!==0?<div className='col-xs-6'>
          {
         productDetails &&  productDetails.images.map((item,index)=>(
              <button type='button' style={{width:'20%', height:'35%'}} key={index} className='btn btn-danger btn-xs'
              onClick={()=>deleteImageUrlPath(item.path,productId)}
              ><i className='fas fa-trash'></i></button>
            ))
          }
        </div>:''
        }
        
      </div>
    
    
    </div>
           
     

    <EditImageForm
   

  
    handleImagesChange={handleImagesChange}
     handleSubmit={handleSubmit} 
 
  />



                                    
</section>
                            </li>
                            
                           
                        </ul>
                    </div>
                </div>
               
            </div>
      
         
        </div>
    </section>


    

</div>



</div>

</div>
</div>
</div>
</div>
</section>
  </Fragment>
  )
}

export default EditImage