import React, { Fragment,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useGetCompanyQuery } from '../../store/api/companyApi'
import Loader from '../../utils/Loader'
import {toast} from 'react-toastify'


const Policy = () => {
    const {isLoading,data,error,isError}=useGetCompanyQuery()


    useEffect(()=>{
        if(isError){
       toast.error(error?.data?.message)
        }
    },[isError])

 

    if(isLoading) return <Loader/>

  return (
    <Fragment>
        
    <div className="breadcrumbs">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="breadcrumbs-content">
                        <h1 className="page-title">Policy</h1>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <ul className="breadcrumb-nav">
                        <li><Link to="/home"><i className="lni lni-home"></i> Home</Link></li>
                        <li>Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
    <section className="about-us section">
        <div className="container">
          {
            data?.map((item,idx)=>(

                <div className="row align-items-center" key={idx}>
          
                <div className="col-12">
                
                  
                        <h2>{item?.name}</h2>
                        <p>
                            {item?.policy}
                        </p>
                        
                    
                </div>
            </div>
            ))
          }
        </div>
    </section>
    
    </Fragment>
  )
}

export default Policy