import React from 'react'
import { ORDER_STATUS } from '../constants/orderStatus'
const UpdateOrderForm = ({ values,
    handleChange, handleSubmit}) => {
        const {tracking, orderStatus}=values ||{}
     
     
  return (
    <section className="contact-us section">
   <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 class="title">Update Order Status</h6>
                                <section class="checkout-steps-form-content collapse show" id="collapseThree"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                   
                                 <form onSubmit={handleSubmit}>
                                 <div class="row">
                                      
                                      
                                              <div class="col-md-6">
                                                <label>Tracking Number</label>
                                               
                                                <input name="tracking" className='form-control'  id="tracking" defaultValue={tracking} onChange={handleChange}/>
                                                </div>
                                          
                                       
                                            <div class="col-md-6">
                                         
                                                <label>Order Status</label>
                                                <select name='orderStatus'  className='form-control' onChange={handleChange} selected>
                                                    <option value={orderStatus}>{orderStatus}</option>
                                               {
                                                ORDER_STATUS.map((item,idx)=>{
                                                    return <option value={item} key={idx}>{item}</option>
                                                })
                                               }
                                              
                                            
                                             
                                                </select>
                                            
                                        </div>
                                        
                                      
                                     
                                        
                                        <div class="col-md-12">
                                            <div class="single-form button">
                                                <button class="btn" type='submit'
                                                   >Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                 </form>
                                </section>
                            </li>
                           
                           
                           


                        </ul>
                    </div>
                </div>
               
            </div>
      
         
        </div>
    </section>
    
  )
}

export default UpdateOrderForm