import React,{useRef} from 'react'
import { Link } from 'react-router-dom'
import { useGetCategoriesQuery } from '../../store/api/categoryApi'
import { useGetCompanyQuery } from '../../store/api/companyApi'
const Footer = () => {
    const {data}=useGetCategoriesQuery()
    const goUp=useRef()
    const {data:company}=useGetCompanyQuery()

    const scrollUp=()=>{
        goUp.current=window.scrollTo(0, 0);
    }
  return (
    
      
    <footer className="footer">
=
    <div className="footer-top">
        <div className="container">
            <div className="inner-content">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-12">
                        <div className="footer-logo">
                        {
                                company?.map((item,idx)=>(
                                    <img src={`https://ervadinepetitdeboutique.com/${item?.logo}`} style={{width:'25%', height:'70px;'}} alt="Logo"/>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8 col-12">
                        <div className="footer-newsletter">
                            <h4 className="title">
                                Subscribe to our Newsletter
                                <span>Get all the latest information, Sales and Offers.</span>
                            </h4>
                            <div className="newsletter-form-head">
                                <form action="#" method="get" target="_blank" className="newsletter-form">
                                    <input name="EMAIL" placeholder="Email address here..." type="email"/>
                                    <div className="button">
                                        <button className="btn">Subscribe<span className="dir-part"></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="footer-middle">
        <div className="container">
            <div className="bottom-inner">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-footer f-contact" >
                        {
                            company?.map((item,idx)=>(
                              
                           <>
                            <h3>Get In Touch With Us</h3>
                            <p className="phone">Phone: +1 {item?.phone}</p>
                          
                            <p className="mail">
                                <a href={`mailto:${item?.email}`}>{item?.email}</a>
                            </p>
                           
                           </>
                     
                            ))
                        }
                         </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      
                        <div className="single-footer our-app">
                            <h3>Our Mobile App</h3>
                            <ul className="app-btn">
                                {/* <li>
                                    <a href="javascript:void(0)">
                                        <i className="lni lni-apple"></i>
                                        <span className="small-title">Download on the</span>
                                        <span className="big-title">App Store</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i className="lni lni-play-store"></i>
                                        <span className="small-title">Download on the</span>
                                        <span className="big-title">Google Play</span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                       
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      
                        <div className="single-footer f-link">
                            <h3>Information</h3>
                            <ul>
                                <li ref={goUp}><Link  onClick={scrollUp} to="/about">About Us</Link></li>
                                <li ref={goUp}><Link  onClick={scrollUp} to="/contact">Contact Us</Link></li>
                                <li ref={goUp}><Link  onClick={scrollUp} to="/policy">Policy</Link></li>
                              
                            </ul>
                        </div>
                       
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                     
                        <div className="single-footer f-link">
                            <h3>Categories</h3>
                            <ul>
                               
                                {
                                    data?.map((item,idx)=>(
                                        <li ref={goUp}><Link key={idx} onClick={scrollUp} to={`/?category=${item.slug}`}>{item.name}</Link></li>
                                    ))
                                }
                            </ul>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div className="footer-bottom">
        <div className="container">
            <div className="inner-content">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-12">
                        <div className="payment-gateway">
                            <span>We Accept:</span>
                            <img src="assets/images/footer/credit-cards-footer.png" alt="#"/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="copyright">
                            <p>ERVADINE <Link to="" rel="nofollow"
                                    target="_blank">PETIT DE BOUTIQUE</Link></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <ul className="socila">
                            <li>
                                <span>Follow Us On:</span>
                            </li>
                            <li><Link to=''><i className="lni lni-facebook-filled"></i></Link></li>
                            <li><Link to=""><i className="lni lni-twitter-original"></i></Link></li>
                            <li><Link to=""><i className="lni lni-instagram"></i></Link></li>
                            <li><Link to=""><i className="lni lni-google"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</footer>


  )
}

export default Footer
