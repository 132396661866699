import React ,{Fragment} from 'react'
import MetaData from '../../../utils/MetaData'
import SalesChart from '../chart/SalesChart'
import SideBar from './SideBar'

const Home = () => {
  return (
    < Fragment>
<MetaData title={"dashboard"}/>
 

 <SideBar />
     </Fragment>
  )
}

export default Home
