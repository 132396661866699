import React, { Fragment, useState } from 'react'
import StarRatings from 'react-star-ratings'
const CreateReview = ({submitHandlerReview,values, handleChange,changeRating,ratings}) => {
    
    const {comment}=values || {}
   

  return (
   <Fragment>
     <div className="modal fade review-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Leave a Review</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                   
                    <div className="row">
                      
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="review-rating">Rating</label>
                                <StarRatings
          rating={ratings}
          starRatedColor="#ffb829"
          numberOfStars={5}
          name='rating'
          starDimension='22px'
          changeRating={changeRating}
        />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="review-message">Review</label>
                        <textarea value={comment} name='comment' onChange={handleChange} className="form-control" rows="8" required></textarea>
                    </div>
                </div>
                 <div className="modal-footer button">
                    <button onClick={submitHandlerReview} type="button" className="btn">Submit Review</button>
                </div>
                
            </div>
        </div>
    </div>
   </Fragment>
  )
}

export default CreateReview