import React,{Fragment,useState} from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CartItems from './CartItems'
import { get_tax_price, get_total_price, shipping_price } from '../../utils/result'

const Cart = () => {
    const {cartItems, cartSubtotal}=useSelector(state=>state.cart)

    const dispatch=useDispatch()


  return (
    <Fragment>
         <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="breadcrumbs-content">
                        <h1 class="page-title">Cart</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <ul class="breadcrumb-nav">
                        <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                        <li><Link to="/home">Shop</Link></li>
                        <li>Cart</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shopping-cart section">
        <div class="container">
            <div class="cart-list-head">
           
                <div class="cart-list-title">
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-12">

                        </div>
                        <div class="col-lg-4 col-md-3 col-12">
                            <p>Product Name</p>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>Quantity</p>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>Price</p>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>Subtotal</p>
                        </div>
                        <div class="col-lg-1 col-md-2 col-12">
                            <p>Remove</p>
                        </div>
                    </div>
                </div>




{
    cartItems?.map(item=>(
        <CartItems items={item}/>
    ))
}


    
            
    </div>
            
            <div class="row">
                <div class="col-12">
                  
                    <div class="total-amount">
                        <div class="row">
                        
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="right">
                                    <ul>
                                        <li>Cart Subtotal<span>${cartSubtotal.toFixed(2)}</span></li>
                                        <li>Shipping<span>{shipping_price(cartSubtotal)?"$"+shipping_price(cartSubtotal):"Free"}</span></li>
                                        <li>Tax<span>${get_tax_price(cartSubtotal)}</span></li>
                                        <li class="last">You Pay<span>${get_total_price(cartSubtotal)}</span></li>
                                    </ul>
                                    <div class="button">
                                        <Link to="/checkout" class="btn">Checkout</Link>
                                        <Link to="/" class="btn btn-alt">Continue shopping</Link>
                                    </div>
                                </div>
                            </div>


                            
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>


    </Fragment>
  )
}

export default Cart
