import React, { Fragment,useState, useEffect } from 'react'
import MetaData from '../../utils/MetaData'
import Loader from '../../utils/Loader'
import {toast} from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import {MDBDataTable} from 'mdbreact'
import { useGetOrdersQuery } from '../../store/api/orderApi'

const OrderItems = () => {

    const {isLoading,isError,error,data}=useGetOrdersQuery()

    useEffect(()=>{
        if(isError){
            toast.error(error?.data.message)
        }
       
    },[isError])

    const setOrders=()=>{
     const orders={
        columns:[
            {
            label:'Order ID',
            field:'id',
            sort:'asc'
        },
        {
            label:'Amount',
            field:'amount',
            sort:'asc'
        },
        {
            label:'Tracking',
            field:'tracking',
        },
        {
            label:'Payment Status',
            field:'status',
            
        },
        {
            label:'Order Status',
            field:'orderStatus',
            sort:'asc'
        },
        {
            label:'Actions',
            field:'actions',
            
        }

    ],
        rows:[]

     }
     data?.orders?.forEach((item)=>{
        orders.rows.push({
            id: item?._id,
            amount: `$`+item?.totalAmount,
            tracking:<Link to={item?.tracking}>{item?.tracking}</Link>,
            orderStatus:item?.orderStatus,
            status: item?.paymentInfo?.status.toUpperCase(),
            actions:<><Link to={`/order/${item?._id}`} className='btn btn-primary'><i className='fa fa-eye'></i></Link>
            
            <Link to={`/invoice/${item?._id}`} className='btn btn-info'><i className='fa fa-print'></i></Link>
            </>

        })
     })
     return orders
    }
    if(isLoading) return <Loader/>
  return (
    <Fragment>
        <MetaData title={"order"}/>
        <MDBDataTable

data={setOrders()}
className="px-3"
bordered
striped
hover
noBottomColumns={true}
/>
    </Fragment>
  )
}

export default OrderItems