import React,{useState} from 'react'
import { Link, NavLink, useLocation, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyLogoutQuery, useLogoutMutation, useLogoutQuery } from '../../store/api/authApi'
import { REMOVE_ITEM_FROM_CART } from '../../store/reducers/cartSlice'
import { useGetCompanyQuery } from '../../store/api/companyApi'


const Header = () => {
 const {isAuthenticated, user}=useSelector(state=>state.auth)
const {cartItems, cartSubtotal}=useSelector(state=>state.cart)
const pathname=useLocation().pathname
const dispatch=useDispatch()
const {data:company}=useGetCompanyQuery()

const navigation=useNavigate()
const [logout,{data}]=useLogoutMutation()

const handleLogout=(e)=>{
 
 
 logout()
setTimeout(()=>{
    window.location.reload()
},1000)
 
  }

  

  const removeItemFromCart=(item)=>{
    const cartItem={
        productID:item?.productID,
        price: item?.price,
        
        qty: item?.qty
      }


      
        dispatch(REMOVE_ITEM_FROM_CART(cartItem))


  
  

   
   
   
  }


  return (
    <div>
      
  

    {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
  
    <header className="header navbar-area">
       
        <div className="topbar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="top-left">
                            <ul className="menu-top-link">
                                <li>
                                    {/* <div className="select-position">
                                        <select id="select4">
                                            <option value="0" selected>$ USD</option>
                                            <option value="1">€ EURO</option>
                                            <option value="2">$ CAD</option>
                                            <option value="3">₹ INR</option>
                                            <option value="4">¥ CNY</option>
                                            <option value="5">৳ BDT</option>
                                        </select>
                                    </div> */}
                                </li>
                                <li>
                                    {/* <div className="select-position">
                                        <select id="select5">
                                            <option value="0" selected>English</option>
                                            <option value="1">Español</option>
                                            <option value="2">Filipino</option>
                                            <option value="3">Français</option>
                                            <option value="4">العربية</option>
                                            <option value="5">हिन्दी</option>
                                            <option value="6">বাংলা</option>
                                        </select>
                                    </div> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* <div className="top-middle">
                            <ul className="useful-links">
                                <li><a href="index.html">Home</a></li>
                                <li><a href="about-us.html">About Us</a></li>
                                <li><a href="contact.html">Contact Us</a></li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="top-end">
                            
          

                            {
                                isAuthenticated?  <div className="user">
                               
                                <div className="dropdown">
                                <i className="lni lni-user"></i>
                                <button className="dd-menu  dropdown-toggle" id="dropdownMenuButton2"
                                  data-bs-toggle="dropdown"
                                            aria-expanded="false" style={{color:'white'}}>Hello {user?.name}</button>

                                            <ul className="sub-menu dropdown-menu" id="submenu-1-2"  aria-labelledby="dropdownMenuButton2">
                                         {
                                            user?.role==="user"?<>
                                               <li><Link className={`${pathname==="/profile"?"dropdown-item active":"dropdown-item"}`} to="/profile">Profile</Link></li>
    <li><Link className={`${pathname==="/my-orders"?"dropdown-item active":"dropdown-item"}`}  to="/my-orders">My Orders</Link></li>
    <li><button  className="btn btn-danger dropdown-item" onClick={handleLogout}>Logout</button></li>
  
   
                                            
                                            </>:<>
                                            <li><Link className={`${pathname==="/dashboard"?"dropdown-item active":"dropdown-item"}`} to="/dashboard">Dashboard</Link></li>
                                            <li><Link className={`${pathname==="/profile"?"dropdown-item active":"dropdown-item"}`} to="/profile">Profile</Link></li>
   
    <li><button  className="btn btn-danger dropdown-item" onClick={handleLogout}>Logout</button></li>
  
     
                                            
                                            </>
                                         }
                                        </ul>
                            </div></div>: <ul className="user-login">
                                <li>
                                    <Link to="/login">Sign In</Link>
                                </li>
                                <li>
                                    <Link to="/register">Register</Link>
                                </li>
                            </ul>
                            }
                        
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            user?.role==='admin'?'':
   <>
        <div className="header-middle">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-7">
                    
                        <Link className="navbar-brand" to="/home">
                            {
                                company?.map((item,idx)=>(
                                    <img src={`https://ervadinepetitdeboutique.com/${item?.logo}`} style={{width:'25%', height:'70px;'}} alt="Logo"/>
                                ))
                            }
                            <br></br>
                            <br></br>
                            <h3>E P B</h3>
                        </Link>
                      
                    </div>
                    <div className="col-lg-5 col-md-7 d-xs-none">
                   
                        <div className="main-menu-search">
                      
                            <div className="navbar-search search-style-5">
                                {/* <div className="search-select">
                                    <div className="select-position">
                                        <select id="select1">
                                            <option selected>All</option>
                                            <option value="1">option 01</option>
                                            <option value="2">option 02</option>
                                            <option value="3">option 03</option>
                                            <option value="4">option 04</option>
                                            <option value="5">option 05</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="search-input">
                                    <input type="text" placeholder="Search"/>
                                </div>
                                <div className="search-btn">
                                    <button><i className="lni lni-search-alt"></i></button>
                                </div>
                            </div>
                         
                        </div>
                       
                    </div>
                    <div className="col-lg-4 col-md-2 col-5">
                        <div className="middle-right-area">
                            <div className="nav-hotline">
                                <i className="lni lni-phone"></i>
                                <h3>Hotline:

                                {
                                company?.map((item,idx)=>(
                                    <span>1+ {item?.phone}</span>
                                ))
                            }
                                    
                                </h3>
                            </div>
                            <div className="navbar-cart">
                                {/* <div className="wishlist">
                                    <Link to="/cart">
                                        <i className="lni lni-heart"></i>
                                        <span className="total-items">{cartItems? cartItems.length:0}</span>
                                    </Link>
                                </div> */}
                                <div className="cart-items">
                                    <Link className="main-btn">
                                        <i className="lni lni-cart"></i>
                                        <span className="total-items">{cartItems? cartItems.length:0}</span>
                                    </Link>
                              
                                    <div className="shopping-item">
                                        <div className="dropdown-cart-header">
                                            <span>{cartItems? cartItems.length:0} Items</span>
                                            <Link to="/cart">View Cart</Link>
                                        </div>
                                        <ul className="shopping-list">
                                          {
                                            cartItems?.map((item,idx)=>(

                                                <li>
                                                <Link  className="remove" title="Remove this item" onClick={()=>removeItemFromCart(item)}><i
                                                            className="lni lni-close" ></i></Link>
                                                    <div className="cart-img-head">
                                   <Link className="cart-img" to={`/product/${item?.productID}`}><img src={`https://ervadinepetitdeboutique.com/${item.image}`} alt={item.name}/></Link>
                                                    </div>
    
                                                    <div className="content">
                                                        <h4><Link to={`/product/${item?.productID}`}>
                                                                {item.name}</Link></h4>
                                                        <p className="quantity">{item.qty}x - <span className="amount">${(item.qty*item.price).toFixed(2)}</span></p>
                                                    </div>
                                                </li>
                                            ))
                                          }


                                          
                                        </ul>
                                        <div className="bottom">
                                            <div className="total">
                                                <span>Total</span>
                                                <span className="total-amount">${cartSubtotal.toFixed(2)}</span>
                                            </div>
                                           {
                                            cartItems.length>0 &&  <div className="button">
                                            <Link to="/checkout" className="btn animate">Checkout</Link>
                                        </div>
                                           }
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-8 col-md-6 col-12">
                    <div className="nav-inner">
                      
                
                     
                        <nav className="navbar navbar-expand-lg">
                            <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                <ul id="nav" className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <NavLink to="/home" className={`${pathname === '/home' ? 'active' : ''}`}  aria-label="Toggle navigation">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/about" className={`${pathname === '/about' ? 'active' : ''}`} aria-label="Toggle navigation">About</NavLink>
                                    </li>
                                   
                                    <li className="nav-item">
                                        <NavLink to="/contact" className={`${pathname === '/contact' ? 'active' : ''}`} aria-label="Toggle navigation">Contact Us</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                     
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                   
                    <div className="nav-social">
                        <h5 className="title">Follow Us:</h5>
                        <ul>
                            <li>
                                <a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><i className="lni lni-instagram"></i></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><i className="lni lni-skype"></i></a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        </>

        }
      
    </header>
    </div>
  )
}

export default Header
