import React from 'react'
import { useGetCategoriesQuery } from '../../../store/api/categoryApi'

const ProductForm = ({values,handleChange,
    handleSubmit,handleImagesChange,previewImages}) => {
    const { name, description, quantity, price,discount, colors}=values||{}

    const {data}=useGetCategoriesQuery()

  return (
    <section className="contact-us section">
   <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 >Add New Product</h6>
                                <section className="checkout-steps-form-content">

   
        
      <div className='row'>

      {previewImages.map((image,index)=><div className='col-md-3'key={index} ><img src={image}  alt={index} style={{width:'30px', height:'30px'}}/></div>)}
  
  
  </div>
         
                                   <form onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Name</label>
                                                <div className="form-input form">
                                                    <input type="text" name='name'value={name} onChange={handleChange} placeholder="Product Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Price</label>
                                                <div className="form-input form">
                                                    <input type="text" name='price'value={price} onChange={handleChange} placeholder="Product Price"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Category</label>
                                                <div className="form-input form">
                                                   <select name='category' className='form-control' onChange={handleChange}>
                                                    <option value="">select..</option>
                                                    {
                                                        data?.map((item,idx)=>(
                                                            <option key={idx} value={item.slug}>{item.name}</option>
                                                        ))
                                                    }
                                                   </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Quantity</label>
                                                <div className="form-input form">
                                                    <input type="text" name='quantity' value={quantity} onChange={handleChange} placeholder="Product Quantity"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Discount</label>
                                                <div className="form-input form">
                                                    <input type="text" name='discount' value={discount} onChange={handleChange} placeholder="Product Discount"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Images</label>
                                                <div className="form-input form">
                                                    <input name="images" type="file" id="images" onChange={handleImagesChange} multiple />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="single-form form-default">
                                                <label>Product Color</label>
                                                <div className="form-input form">
                                                    <input type="text" name='colors' value={colors} onChange={handleChange} placeholder="Product Color"/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="single-form form-default">
                                                <label>Description</label>
                                                <textarea className='form-control' name="description" value={description} onChange={handleChange} cols="60" rows="8" id="description" ></textarea>
                                            </div>
                                        </div>
                                    
                                        <div className="col-md-12">
                                            <div className="single-form button">
                                                <button className="btn" type='submit'
                                                   >Add New Product</button>
                                            </div>
                                        </div>
                                       
                                    </div>

                                    </form>
                                </section>
                            </li>
                            
                           
                        </ul>
                    </div>
                </div>
               
            </div>
      
         
        </div>
    </section>
    
  )
}

export default ProductForm