import React, { Fragment, useEffect} from 'react'
import ProductDetailsItem from './ProductDetailsItem'
import MetaData from '../../utils/MetaData'

import { Link, useParams } from 'react-router-dom'

import {toast} from 'react-toastify'
import Loader from '../../utils/Loader'
import {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useGetProductDetailQuery } from '../../store/api/productApi'

const ProductDetails = () => {
   
    
    const params=useParams()
    const {productId}=params
    const {data,isLoading,error,isError}=useGetProductDetailQuery(productId)
    useEffect(()=>{
        if(isError){
toast.error(error?.data?.message)
        }
    },[isError])

    if(isLoading) return <Loader/>
  return (
  <Fragment>
    <MetaData title={"product-details"}/>
   
    <div class="breadcrumbs" id="section1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="breadcrumbs-content">
                        <h1 class="page-title">Single Product</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <ul class="breadcrumb-nav">
                        <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                        <li><Link to="/">Shop</Link></li>
                        <li>Single Product</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ProductDetailsItem product={data} ControlledZoom={ControlledZoom} />
  </Fragment>
  )
}

export default ProductDetails
