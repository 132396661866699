import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { SetAuthenticated, SetUser, setLoading } from '../reducers/userSlice'
export const userApi=createApi({
   reducerPath:'userApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),
   tagTypes:["User"],
   endpoints: (builder)=>({
    
    profile: builder.query({
      query: ()=> {return {url:'/profile', credentials: 'include'}},
    
        transformResponse:(response)=>response.user,
        async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

            try {
               
               const {data}=await queryFulfilled 
               dispatch(SetUser(data))
               dispatch(SetAuthenticated(true))
               dispatch(setLoading(false))
            } catch (error) {
              dispatch(setLoading(false))
            console.log(error)
            }
          },
       providesTags: ["User"]
    }),


    updateUser:builder.mutation({
      query:({id,body})=>{
        return{
          url:`/update-user/${id}`,
          credentials: 'include',
          method:'PUT',
          body
        }
      },
      invalidatesTags:["User"]
    }),

    updateUserProfile:builder.mutation({
      query:(body)=>{
        return{
          url:`/update-profile`,
          credentials: 'include',
          method:'PUT',
          body
        }
      },
      invalidatesTags:["User"]
    }),
  
  

    updatePassword:builder.mutation({
      query:(body)=>{
        return{
          url:'/update-password',
          credentials: 'include',
          method:'PUT',
          body
        }
      },
     invalidatesTags:['User']
    }),
    deleteUser:builder.mutation({
      query:(id)=>{
        return{
          url:`/delete-user/${id}`,
          credentials: 'include',
          method:'DELETE',
        
        }
      },
     invalidatesTags:['User']
    }),
    forgotPassword:builder.mutation({
      query:(body)=>{
        return{
          url:'/forgot/password',
          method:'POST',
          body
        }
      },
   
    }),


    getAllUsers:builder.query({
      query:()=>{
        return{
          url:'/admin/users',
          method:'GET',
          credentials:'include'
        
        }
      },
   
    }),

    userDetails:builder.query({
      query:(id)=>{
        return{
          url:`/user-details/${id}`,
          method:'GET',
          credentials:'include'
        
        }
      },
   
    }),


    

  

    resetPassword:builder.mutation({
      query:({token,body})=>{
        return{
          url:`/new-password/${token}`,
          method:'PUT',
          body
        }
      },
   
    })
 
   })
})

export const {useUpdateUserMutation, 
  useUpdatePasswordMutation, useForgotPasswordMutation,
  useGetAllUsersQuery,
  useResetPasswordMutation,useProfileQuery, 
  useDeleteUserMutation,useUserDetailsQuery, useUpdateUserProfileMutation}=userApi
