import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { userApi } from './userApi'

export const authApi=createApi({
   reducerPath:'authApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),


   endpoints: (builder)=>({


    
    login: builder.mutation({
        query(body){
          return{
            url: '/login',
            method: "POST",
            credentials: 'include',
            body
          }

       
        },

        async onQueryStarted(arg,{ dispatch, getState, queryFulfilled}) {

          try {
             
             await queryFulfilled 
             dispatch(userApi.endpoints.profile.initiate(null))
            
          } catch (error) {
                        console.log(error)
          }
        },

      
    }),

    register: builder.mutation({
      query(body){
        return{
          url: '/register',
          method: "POST",
          credentials: 'include',
          body
        }
      },
    
  }),
  activateAccount:builder.mutation({

    query:(id)=>{
      return{
        url: `/activate-account/${id}`,
        method: "put",
        credentials: 'include'
      }
    },
   
  }),
  logout:builder.mutation({
    query:()=>{
      return{
        url: '/logout',
        method:'POST',
        credentials: 'include',
        
      }
    }
  }),



   })


   
})

export const {useLoginMutation, useRegisterMutation,useLogoutMutation, useActivateAccountMutation}=authApi
