import React from 'react'

const ShippingForm = ({list,defaultChecked,handleChecked,same=false,profile,handleChange,handleSubmit,values=null}) => {
    
    const {address,city,zipCode,state}=values||{}
  return (
   <form onSubmit={handleSubmit}>
      <div className="row">
                                        
                                       
                                       {
                                        same===true && profile &&
                                        <>
                                         <div className="col-md-12">
                                            <div className="single-form form-default">
                                                <label>Biling Address</label>
                                                <div className="form-input form">
                                                    <input type="text" name='address' required defaultValue={profile?.address} onChange={handleChange} placeholder="Address"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>City</label>
                                                <div className="form-input form">
                                                    <input type="text" name='city' required defaultValue={profile?.city} onChange={handleChange} placeholder="City"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Post Code</label>
                                                <div className="form-input form">
                                                    <input name='zipCode' required defaultValue={profile?.zipCode} onChange={handleChange} type="text" placeholder="Post Code"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Country</label>
                                                <div className="form-input form">
                                                    <input type="text" defaultValue={"USA"} placeholder="Country"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Region/State</label>
                                                <div className="select-items">
                                                <select className="form-control"  name='state' onChange={handleChange} >
                                                        <option defaultValue={profile?.state}>{profile?.state}</option>
                                                       {
                                                        list.map((item,idx)=>(
                                                            <option key={idx} value={item.name}>{item.name}</option>
                                                        ))
                                                       }
                                                      
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                       
                            <div className="col-md-12">
                                            <div className="single-checkbox checkbox-style-3">
                                                <input type="checkbox" id='checkbox'  defaultChecked={defaultChecked} onChange={handleChecked}/>
                                                <label htmlFor="checkbox"><span></span></label>
                                                <p>My Billing and mailing addresses are the same.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="steps-form-btn button">
                                                <button type='submit' className="btn">Save & Continue</button>
                                               
                                            </div>
                                        </div>
                                        </>
                                       }


                                         
{
                                        same===false && profile &&
                                        <>
                                           <div className="col-md-12">
                                            <div className="single-form form-default">
                                                <label>Biling Address</label>
                                                <div className="form-input form">
                                                    <input type="text" name='address' required defaultValue={profile?.address} onChange={handleChange} placeholder="Address"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>City</label>
                                                <div className="form-input form">
                                                    <input type="text" name='city' required defaultValue={profile?.city} onChange={handleChange} placeholder="City"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Post Code</label>
                                                <div className="form-input form">
                                                    <input name='zipCode' required  defaultValue={profile?.zipCode} onChange={handleChange} type="text" placeholder="Post Code"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Country</label>
                                                <div className="form-input form">
                                                    <input type="text" defaultValue={"USA"} placeholder="Country"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Region/State</label>
                                                <div className="select-items">
                                                <select className="form-control"  name='state' onChange={handleChange} >
                                                        <option defaultValue={""}>select</option>
                                                       {
                                                        list.map((item,idx)=>(
                                                            <option key={idx} value={item.name}>{item.name}</option>
                                                        ))
                                                       }
                                                      
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                       
                            <div className="col-md-12">
                                            <div className="single-checkbox checkbox-style-3">
                                                <input type="checkbox" id='checkbox'  defaultChecked={defaultChecked} onChange={handleChecked}/>
                                                <label htmlFor="checkbox"><span></span></label>
                                                <p>My billing and mailing addresses are the same.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="steps-form-btn button">
                                                <button type='submit' className="btn">Save & Continue</button>
                                               
                                            </div>
                                        </div>
                                        </>
                                       }

  
{
                                        same===false && 
                                        <>
                                         <div className="col-md-12">
                                            <div className="single-form form-default">
                                                <label>Biling Address</label>
                                                <div className="form-input form">
                                                    <input type="text" name='address' required defaultValue={address} onChange={handleChange} placeholder="Address"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>City</label>
                                                <div className="form-input form">
                                                    <input type="text" name='city' required defaultValue={city} onChange={handleChange} placeholder="City"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Post Code</label>
                                                <div className="form-input form">
                                                    <input name='zipCode' required defaultValue={zipCode} onChange={handleChange} type="text" placeholder="Post Code"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Country</label>
                                                <div className="form-input form">
                                                    <input type="text" defaultValue={"USA"} placeholder="Country"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Region/State</label>
                                                <div className="select-items">
                                                <select className="form-control"  name='state' onChange={handleChange} >
                                                        <option defaultValue={state?state:""}>{state?state:"select"}</option>
                                                       {
                                                        list.map((item,idx)=>(
                                                            <option key={idx} value={item.name}>{item.name}</option>
                                                        ))
                                                       }
                                                      
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                       
                            <div className="col-md-12">
                                            <div className="single-checkbox checkbox-style-3">
                                                <input type="checkbox" id='checkbox'  defaultChecked={defaultChecked} onChange={handleChecked}/>
                                                <label htmlFor="checkbox"><span></span></label>
                                                <p>My billing and mailing addresses are the same.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="steps-form-btn button">
                                                <button type='submit' className="btn">Save & Continue</button>
                                               
                                            </div>
                                        </div>
                                        </>
                                       }


                                    </div>
   </form>
  )
}

export default ShippingForm