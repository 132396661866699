import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { DELETE_CATEGORY, setLoading } from '../reducers/categorySlice'

export const categoryApi=createApi({
   reducerPath:'categoryApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),
   tagTypes:["Category"],
   endpoints: (builder)=>({
   
    newCategory: builder.mutation({
        query:(body)=>{
            return{
                url:'/new-category',
                method:'POST',
                credentials:'include',
                body
            }
        },
        invalidatesTags:['Category']
    }),
    getCategories:builder.query({
        query:()=>{
          return{
            url:"/admin/get-categories",
            method:'GET'
          

          }
        },
      
    }),

    categoryDetails:builder.query({
      query:(id)=>{
          return{
            url:`/single-category/${id}`,
            credentials: 'include',
            method:'GET'
          }
        },

  }),
  updateCategory:builder.mutation({
    query:({id,body})=>{
      return{
        url:`/edit-category/${id}`,
        credentials: 'include',
        method:'PATCH',
        body
      }
    },
    invalidatesTags:["Category"]

  }),


  deleteCategory:builder.mutation({
      query:(id)=>{
        return{
          url:`/delete-category/${id}`,
          credentials: 'include',
          method:'DELETE'
        }
      },
      transformResponse:(response)=>response,
      async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

          try {
             
             const {data}=await queryFulfilled 
             dispatch({
              type: DELETE_CATEGORY,
              categoryId: data?._id
              
             })
             dispatch(setLoading(false))
          } catch (error) {
            dispatch(setLoading(false))
          console.log(error)
          }
        },
      invalidatesTags:["Category"]

    }),
    
 
   })
})

export const {useNewCategoryMutation,useGetCategoriesQuery, 
  useDeleteCategoryMutation,useCategoryDetailsQuery,useUpdateCategoryMutation}=categoryApi
