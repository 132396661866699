import React, { Fragment } from 'react'
import OrderItems from './OrderItems'
import { Link } from 'react-router-dom'
const Orders = () => {
  return (
    <Fragment>
    <div class="breadcrumbs">
   <div class="container">
       <div class="row align-items-center">
           <div class="col-lg-6 col-md-6 col-12">
               <div class="breadcrumbs-content">
                   <h1 class="page-title">Orders</h1>
               </div>
           </div>
           <div class="col-lg-6 col-md-6 col-12">
               <ul class="breadcrumb-nav">
                   <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                   <li><Link to="/">Shop</Link></li>
                   <li>Orders</li>
               </ul>
           </div>
       </div>
   </div>
</div>
<div class="shopping-cart section">
   <div class="container">
       <div class="cart-list-head">
      
           <div class="cart-list-title">
               <div class="row">
                   <div class="col-12">
<h6>My Orders</h6>
                   </div>
                 
                  
                   
                  
                   
               </div>
           </div>




           <OrderItems/>



       
</div>
       
      
   </div>
</div>


</Fragment>
  )
}

export default Orders
