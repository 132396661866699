import React, { Fragment, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import { useActivateAccountMutation } from '../../store/api/authApi'
import {toast} from 'react-toastify'
import Loader from '../../utils/Loader'


const ActivateAccount = () => {
    const params=useParams()
    const {userId}=params;
    const [activateAccount,{isLoading,error,data, isError,isSuccess}]=useActivateAccountMutation()
 


   

      useEffect(()=>{
       
        activateAccount(userId)

       
        
       
      },[userId])

    

    if(isLoading) return <Loader/>
  return (
<Fragment>
<div class="maill-success">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="success-content">
          <i class="fa fa-check" aria-hidden="true"></i>
            <h2>{isSuccess? data?.message: error?.data?.message}</h2>
            <p>Thanks for joinning.</p>
            <div class="button">
              <Link to="/login" class="btn">Back to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Fragment>
  )
}

export default ActivateAccount