import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetOrderDetailsQuery } from '../../store/api/orderApi'
import moment from 'moment'
import MetaData from '../../utils/MetaData'
import Loader from '../../utils/Loader'
import {toast} from 'react-toastify'
import * as _html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import './invoice.css'
const Invoice = () => {
    const html2canvas = _html2canvas;
    const params=useParams()
    const {orderId}=params
        const {isError, isLoading, error,data}=useGetOrderDetailsQuery(orderId)
      const [orders, setOrders]=useState([])
        
    useEffect(()=>{
        if(isError){
            toast.error(error?.data?.message)
        }
    setOrders(data?.order)
    },[isError])


    const handleDownload=()=>{
        const input=document.getElementById("order_invoice")
        html2canvas(input).then((canvas)=> {
            const doc = new jsPDF({
                orientation: "portrait",
                unit: "in",
                format: [10, 10]
              });
           const imgData=canvas.toDataURL("image/png")
         const size=doc.internal.pageSize.getWidth()
         doc.addImage(imgData,"PNG",0,0,size, 0)
         doc.save(`invoice${orderId}.pdf`)
        })
        
    }
    
        if(isLoading) return <Loader/>
    
  return (
   <Fragment>
    <MetaData title={"invoice"}/>
    <div className="page-content container" id='order_invoice'>
    <div className="page-header text-blue-d2">
        <h1 className="page-title text-secondary-d1">
            Invoice
            <small className="page-info">
                <i className="fa fa-angle-double-right text-80"></i>
                ID: #{orderId}
            </small>
        </h1>

        <div className="page-tools">
            <div className="action-buttons">
                <button className="btn bg-white btn-light mx-1px text-95" onClick={handleDownload} data-title="Print">
                    <i className="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                    Print
                </button>
                <Link className="btn bg-white btn-light mx-1px text-95" to="/my-orders" data-title="PDF">
                    <i className="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                    Go back to orders
                </Link>
            </div>
        </div>
    </div>

    <div className="container px-0">
        <div className="row mt-4">
            <div className="col-12 col-lg-12">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center text-150">
                           
                            <span className="text-default-d3">Ervadine Petit De Boutique</span>
                        </div>
                    </div>
                </div>
              

                <hr className="row brc-default-l1 mx-n1 mb-4" />

                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            <span className="text-sm text-grey-m2 align-middle">To: </span> <span className="text-600 text-110 text-blue align-middle"> {data?.user?.name}</span>
                        </div>
                        <div className="text-grey-m2">
                            <div className="my-1">
                                {data?.shippingInfo?.address}, {data?.shippingInfo?.city}
                            </div>
                            <div className="my-1">
                            {data?.shippingInfo?.state}, {data?.shippingInfo?.zipCode}
                            </div>
                            
                        </div>
                    </div>
                 

                    <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                        <hr className="d-sm-none" />
                        <div className="text-grey-m2">
                            <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                Invoice
                            </div>

                            <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">ID:</span> #{orderId}</div>

                            <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Issue Date:</span> {moment(data?.createdAt).format("MM/DD/Y")}</div>

<div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Status:</span> <span>{data?.paymentInfo?.status==="succeeded"?"PAID":"UNPAID"}</span></div>
                        </div>
                    </div>
                  
                </div>

                <div className="mt-4">
                    <div className="row text-600 text-white bgc-default-tp1 py-25">
                       
                        <div className="col-9 col-sm-5">Product</div>
                        <div className="d-none d-sm-block col-4 col-sm-2">Qty</div>
                        <div className="d-none d-sm-block col-sm-2">Unit Price</div>
                        <div className="col-2">Amount</div>
                    </div>

                    <div className="text-95 text-secondary-d3">
                      {
                        data?.orderItems?.map((item,idx)=>(

                            <div className="row mb-2 mb-sm-0 py-25" key={idx}>
                        
                            <div className="col-9 col-sm-5">{item.name}</div>
                            <div className="d-none d-sm-block col-2">{item.qty}</div>
                            <div className="d-none d-sm-block col-2 text-95">${item.price}</div>
                            <div className="col-2 text-secondary-d2">${(item.qty*item.price).toFixed(2)}</div>
                        </div>
                        ))
                      }

                     


                       
                    </div>

                    <div className="row border-b-2 brc-default-l2"></div>

                  
          
            

                    <div className="row mt-3">
                       

                        <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                            <div className="row my-2">
                                <div className="col-7 text-right">
                                    SubTotal
                                </div>
                                <div className="col-5">
                                    <span className="text-120 text-secondary-d1">${data?.itemsPrice}</span>
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col-7 text-right">
                                    Tax (10%)
                                </div>
                                <div className="col-5">
                                    <span className="text-110 text-secondary-d1">${data?.taxAmount}</span>
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col-7 text-right">
                                    Total Amount
                                </div>
                                <div className="col-5">
                                    <span className="text-150 text-success-d3 opacity-2">${data?.totalAmount}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div>
                        <span className="text-secondary-d1 text-105">Thank you for your order</span>
                        <hr></hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
   </Fragment>
  )
}

export default Invoice
