import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const ProductByCategory = ({product}) => {
  
 
  return (
   <Fragment>
     
                {/* <div className="col-lg-6 col-md-6 col-12 ">
                    <div className='single-banner'>
                    <img alt=''  src={`http://localhost:8000/${product?.image}`} width={"300px"}/>
                    </div>
               
                        <div className="content">
                            <h2>{product?.name}</h2>
                            <p>{product?.description} <br></br></p>
                            <div className="button">
                                <Link to={`/products/?category=${product?.slug}`} className="btn">View Details</Link>
                            </div>
                        </div>
                    </div>
             */}

                    <div className="col">
          <div className="card shadow-sm">
            <img className="bd-placeholder-img card-img-top" src={`https://ervadinepetitdeboutique.com/${product?.image}`} style={{width:"100%", height:"250"}}  alt=''/>
           
            <div className="card-body">
            <h4>{product?.name}</h4>
            <hr></hr>
              <p className="card-text">{product?.description}</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group">
                <Link className="btn btn-sm btn-info" to={`/?category=${product?.slug}`} >View Details</Link>
                  
                </div>
              
              </div>
            </div>
          </div>
        </div>
              
        

   </Fragment>
  )
}

export default ProductByCategory
