import React from 'react'




const EditImageForm = ({handleImagesChange,handleSubmit}) => {
  



  return (
   
                                            <form onSubmit={handleSubmit}>
                                    <div className="row">
                                 
                                      
                                      
                                     

                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Image</label>
                                                <div className="form-input form">
                                                    <input type="file" name='images' onChange={handleImagesChange} multiple/>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    
                                        <div className="col-md-12">
                                            <div className="single-form button">
                                                <button className="btn" type='submit'
                                                   >Edit Image</button>
                                            </div>
                                        </div>
                                       
                                    </div>

                                    </form>


    
  )
}

export default EditImageForm