import React,{useState,useEffect} from 'react'
import ShippingForm from '../form/ShippingForm'
import Checkout from './Checkout'
import {UsaStates} from 'usa-states'
import CheckoutStep from './CheckoutStep'
import Loader from '../../utils/Loader'
import {toast} from 'react-toastify'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SHIPPING_INFO } from '../../store/reducers/cartSlice'

const Shipping = () => {
    var usStates = new UsaStates();
    const states=Object.values(usStates.states)
    const {user}=useSelector(state=>state.auth)
    const existingBiling=JSON.parse(localStorage.getItem('shippingInfo'))
    const [sameBilling,setSameBilling]=useState(false);
   const navigation=useNavigate()
   const dispatch=useDispatch()
    const [values, setValues]=useState({
      address:'',city:"",zipCode:"",state:""
    })
 
    const {zipCode,address,city,state}=values
    let existingBilingData;
    let billings

    console.log(sameBilling)
    
    useEffect(()=>{
      sameBilling && setValues({ ...values, ...user})
      
      
    },[])
  
  
    useEffect(()=>{
     
      sameBilling===false && existingBiling!==null &&  setValues({ ...values, ...existingBiling })
      
    },[])


    const handleChange=(e)=>{
      setValues({...values,[e.target.name]: e.target.value})
    }

    const handleChecked=(e)=>{
      setSameBilling(e.target.checked)
    }

    const handleSubmit=(e)=>{
      e.preventDefault()
      if(!existingBilingData && sameBilling===false){
        const savedata={
         
          address,
          city,
          zipCode,
          state,
          
        }

        dispatch(SHIPPING_INFO(savedata))
        navigation('/payment')
      }else{
        dispatch(SHIPPING_INFO(user))
        navigation('/payment')
      }
       
      
    }

  
    if(sameBilling===true){
      billings=<ShippingForm list={states}  defaultChecked={sameBilling} handleChecked={handleChecked} same={sameBilling} profile={user} handleChange={handleChange} handleSubmit={handleSubmit}/>
   }if(sameBilling===false && existingBiling!==null){
   billings=<ShippingForm list={states} same={sameBilling}  defaultChecked={sameBilling} handleChecked={handleChecked} profile={existingBiling} handleChange={handleChange} handleSubmit={handleSubmit}/>
   }if(sameBilling===false){
      billings=<ShippingForm list={states} values={values} defaultChecked={sameBilling} handleChecked={handleChecked} same={sameBilling}  handleChange={handleChange} handleSubmit={handleSubmit}/>
   }

  return (
    <Checkout
     step={<CheckoutStep checkout="checkout" shipping="shipping"/>}

     children={billings}
    />
  )
}

export default Shipping