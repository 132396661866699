import React, { Fragment } from 'react'
import StarRatings from 'react-star-ratings'

const ReviewsList = ({review,reviews,isAuthenticated}) => {

    const redirectToLogin=()=>{
        window.location.href='/#/login'
    }
  return (
    <Fragment>
          <div className="col-lg-4 col-12">
                        <div className="single-block give-review">
                            <h4>{review?.ratings} (Overall)</h4>
                            <ul>
                                <li>

                                    {
                                        reviews?.map((item,idx)=>(
                                        <div key={idx}>
                                          <span>{item?.rating} star (s)</span>
                                          <StarRatings
          rating={Number(item?.rating)}
          starRatedColor="#ffb829"
          numberOfStars={5}
          name='rating'
          starDimension='22px'
         
        />
                                        </div>

                                        ))
                                    }
                                  
                                </li>
                                
                               
                               
                              
                            </ul>
                          
                         {
                            isAuthenticated ?   <button type="button" className="btn review-btn" data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                            Leave a Review
                        </button>:
                           <button onClick={redirectToLogin} type="button" className="btn review-btn">
                           Login first to comment
                       </button>
                         }
                        </div>
                    </div>

         <div className="col-lg-8 col-12">
                        <div className="single-block">
                            <div className="reviews">
                                <h4 className="title">Latest Reviews</h4>
                             
                             {
                                reviews?.map((item,idx)=>(
                                    <div className="single-review" key={idx}>
                                      
                                    <img src="https://via.placeholder.com/150x150" alt="#"/>
                                    <div className="review-info">
                                        <h4>
                                            <span>{item?.user?.name}
                                            </span>
                                        </h4>
                                        <ul className="stars">
                                        <StarRatings
          rating={Number(item?.rating)}
          starRatedColor="#ffb829"
          numberOfStars={5}
          name='rating'
          starDimension='22px'
         
        />
                                        </ul>
                                        <p>{item?.comment}</p>
                                    </div>
                                </div>
                                ))
                             }
                            
                               
                              
                               
                               
                            </div>
                        </div>
                    </div>

    </Fragment>
  )
}

export default ReviewsList