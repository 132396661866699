import React from 'react'

const CreditCardForm = ({CardNumberElement,loading,CardExpiryElement, CardCvcElement,options, paymentHandler}) => {
  return (
   <form  onSubmit={paymentHandler}>
      <div className="row">
                                        <div className="col-8">
                                            <div className="checkout-payment-form">
                                               


                                                <div className="single-form form-default">
                                                    <label>Card Number</label>
                                                    <CardNumberElement 
                                                           className="form-input form"
                                                           id="credit-input" type="text"
                                                            placeholder="0000 0000 0000 0000"
                                                           
                                                           options={options}
                                                    />
                                                    <img src="assets/images/payment/card.png" alt="card"/>
                                                </div>
                                                <div className="payment-card-info">
                                                    <div className="single-form form-default mm-yy">
                                                        <label>Expiration</label>
                                                        <div className="expiration d-flex">
                                                            <CardExpiryElement className="form-input form"
                                                                 type="text" placeholder="MM-YYYY"
                                                            
                                                            
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="single-form form-default mm-yy">
                                                        <label>CVV</label>
                                                        <div className="expiration d-flex">
                                                        <CardCvcElement className="form-input form"
                                                             type="text" placeholder="CVV"
                                                        />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-form form-default button">
                                                   {  

<button disabled={loading} id="btn_pay" className="btn" type="submit">{loading?"Loading...":"Pay Now"}</button>

                                                   
                                                   }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
   </form>
  )
}

export default CreditCardForm
