import React, { Fragment } from 'react'

const CompanyItem = ({company, editCompanyHandler, deleteCompanyHandler}) => {
  return (
    <Fragment>

{
    company?.map((item,idx)=>(

        <tr key={idx}>
             <td>{item?.name}</td>
      <th scope="row">{item?.email}</th>
     
      <td>{item?.phone}</td>
      <td><button className='btn btn-info btn-sm' onClick={()=>editCompanyHandler(item?._id)}><i className='fas fa-edit'></i></button> <button className='btn btn-danger btn-sm' onClick={()=>deleteCompanyHandler(item?._id)}><i className='fas fa-trash'></i></button></td>
    </tr>
    ))
}

    </Fragment>
  )
}

export default CompanyItem
