import React from 'react'

const PersonnalForm = ({list, values=null,sameBilling,handleChecked, handleChange, handleSubmit}) => {
    const {email,name,phoneNumber,address,city,zipCode,state}=values||{}
  return (
  <form onSubmit={handleSubmit}>
       <div className="row">

       
                                        <div className="col-md-12">
                                            <div className="single-form form-default">
                                                {/* <label>User Name</label> */}
                                                <div className="row">
                                                    
                                                    <div className="col-md-6 form-input form">
                                                        <input defaultValue={name} name='name' onChange={handleChange} type="text" placeholder="Full name"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Email Address</label>
                                                <div className="form-input form">
                                                    <input defaultValue={email} name='email' onChange={handleChange} type="text" placeholder="Email Address"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Phone Number</label>
                                                <div className="form-input form">
                                                    <input name='phoneNumber' defaultValue={phoneNumber} onChange={handleChange} type="text" placeholder="Phone Number"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-form form-default">
                                                <label>Mailing Address</label>
                                                <div className="form-input form">
                                                    <input name='address' defaultValue={address} onChange={handleChange}  type="text" placeholder="Mailing Address"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>City</label>
                                                <div className="form-input form">
                                                    <input name='city' defaultValue={city} onChange={handleChange}  type="text" placeholder="City"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Post Code</label>
                                                <div className="form-input form">
                                                    <input name='zipCode' defaultValue={zipCode} onChange={handleChange}  type="text" placeholder="Post Code"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Country</label>
                                                <div className="form-input form">
                                                    <input type="text" name='country' defaultValue="USA" placeholder="Country"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Region/State</label>
                                                <div className="select-items">
                                                    <select className="form-control"  name='state' onChange={handleChange} >
                                                        <option defaultValue={state?state:""}>{state?state:"select"}</option>
                                                       {
                                                        list.map((item,idx)=>(
                                                            <option key={idx} value={item.name}>{item.name}</option>
                                                        ))
                                                       }
                                                      
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className="col-md-12">
                                            <div className="single-form button">
                                                <button className="btn" type='submit'>next
                                                    step</button>
                                            </div>
                                        </div>

                                        
                                    </div>
  </form>
  )
}

export default PersonnalForm