import React from 'react'
import {Bar,Line, Pie,Bubble} from 'react-chartjs-2-update';
import moment from 'moment'
const SalesChart = ({startDate,endDate,sales=[]}) => {
  const getsales=sales?.map(item=>item?._getSales)
  const orders=sales?.map(item=>item?.numOfOrders)
  const dates=sales?.map(item=>item?.date)
  
   
const state = {
    labels: dates,
    datasets: [
      {
        label: 'Sales',
        fill: false,
      lineTension: 0.5,
        backgroundColor: 'rgba(0,0,0,1)',
        borderColor: '#1A9C34',
        borderWidth: 2,
        data: getsales
      },
      {
        label: 'Orders',
        fill: false,
      lineTension: 0.5,
        backgroundColor: 'rgba(0,0,0,1)',
        borderColor: '#F04A19 ',
        borderWidth: 2,
        data: orders
      }
    ]
  }
  
 


return(<>
<Line
          data={state}
          options={{
            title:{
              display:true,
              text:`Sales from ${moment(startDate).format("Y-MM-DD")} to ${moment(endDate).format('Y-MM-DD')}`,
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
</>)
  
}

export default SalesChart
