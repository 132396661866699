import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import userSlice from './reducers/userSlice'
import { authApi } from './api/authApi'
import { userApi } from './api/userApi'
import { categoryApi } from './api/categoryApi'
import { productApi } from './api/productApi'
import cartSlice from './reducers/cartSlice'
import { orderApi } from './api/orderApi'
import { companyApi } from './api/companyApi'
import companySlice from './reducers/companySlice'
import categorySlice from './reducers/categorySlice'
import productSlice from './reducers/productSlice'
import orderSlice from './reducers/orderSlice'
import { messageApi } from './api/messageApi'

 const store=configureStore({

  
    reducer:{
      auth: userSlice,
      cart: cartSlice,
      company: companySlice,
      categories: categorySlice,
      products: productSlice,
      orders: orderSlice,

      [authApi.reducerPath]: authApi.reducer,
     [userApi.reducerPath]: userApi.reducer,
     [categoryApi.reducerPath]:categoryApi.reducer,
     [productApi.reducerPath]: productApi.reducer,
     [orderApi.reducerPath]: orderApi.reducer,
     [companyApi.reducerPath]:companyApi.reducer,
     [messageApi.reducerPath]:messageApi.reducer
    },

   

    middleware: (getDefaultMiddleware)=>getDefaultMiddleware().concat([authApi.middleware,
         userApi.middleware, categoryApi.middleware,
          productApi.middleware, orderApi.middleware, companyApi.middleware, messageApi.middleware])
})


export default store