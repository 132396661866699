import { createSlice } from "@reduxjs/toolkit";



const companySlice=createSlice({
    name:"companySlice",
    initialState:{
      company: null,
      loading:true
    },
    
    reducers:{
        SetCompany:(state,action)=>{
       
            
              state.company=action.payload
            
        },
       

       
        setLoading:(state,action)=>{
          state.loading=action.payload
        },
    
        DELETE_COMPANY:(state,action)=>{
         const {payload}=action;
          state.company= Array.from(state.company).filter(x=>x.id!==payload.companyId)
          
        }


    }
})

export const {SetCompany,DELETE_COMPANY, setLoading}=companySlice.actions;
export default companySlice.reducer;