import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'

import { Link } from 'react-router-dom'
import {toast} from 'react-toastify'
import Loader from '../../../utils/Loader'
import { useNewCompanyMutation } from '../../../store/api/companyApi'
import CompanyForm from '../form/CompanyForm'
const AddCompany = () => {

  const [newCompany, {isLoading,isError,error,isSuccess,data}]=useNewCompanyMutation()
  const [values,setValues]=useState({name:'',phone:'',email:'',address:'',state:'',zipCode:'',description:'',city:'',policy:'',logo:''})

  const {name,phone,email,address,state,zipCode,city,policy,logo,description}=values

  const [preview, setPreview] = useState(
    "https://via.placeholder.com/100x100.png?text=PREVIEW"
  );
  const handleImageChange = (e) => {
      // console.log(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setValues({ ...values, logo: e.target.files[0] });
    };
  
    const handleChange = (e) => {
      setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log(values);
      // console.log(location);
  
      let formData = new FormData();

      formData.append("name", name.trim());
      formData.append("email", email.trim());
      formData.append("phone", phone.trim());
      formData.append("address", address.trim());
      formData.append("state", state.trim());
      formData.append("zipCode", zipCode.trim()); 
      formData.append("city", city.trim());
      formData.append("policy", policy.trim());
      formData.append("description", description.trim());
      logo && formData.append("logo", logo);
  
      
  
     try {
      newCompany(formData)
     
      
      
     } catch (er) {
       toast.error(error?.data.message)
     }
  
    }


    useEffect(()=>{
       if(isSuccess){
        toast.success(data?.message)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
       }
    },[isSuccess])


    useEffect(()=>{
      if(isError){
       toast.error(error?.data?.message)
       
      }
   },[isError])


  if(isLoading) return <Loader/>
  return (
  <Fragment>
<MetaData title={"add company"}/>
<section className="product-grids section">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-12">
             
                <div className="product-sidebar">
             
                    
                 
                    <div className="single-widget">
                        <h3><Link to="/dashboard">Dashboard</Link></h3>
                        <ul className="list">
                        <li>
                                <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                            </li>
                            <li>
                                <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                            </li>
                            <li>
                            <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                            </li>
                            <li>
                            <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                            </li>
                            <li>
                            <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                            </li>

                            <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                            
                        </ul>
                    </div>
              
                 
                  
                    
                </div>
               
            </div>


            <div className="col-lg-9 col-12">
                <div className="product-grids-head">
            <div className="tab-content" id="nav-tabContent">

<div className="tab-pane fade show active" id="nav-grid" role="tabpanel"
    aria-labelledby="nav-grid-tab">


 
    <CompanyForm preview={preview} values={values} handleChange={handleChange} 
    handleImageChange={handleImageChange} handleSubmit={handleSubmit}/>





    

</div>



</div>

</div>
</div>
</div>
</div>
</section>
  </Fragment>
  )
}

export default AddCompany