import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { DELETE_IMAGE_PATH, DELETE_PRODUCT,setLoading} from '../reducers/productSlice'

export const productApi=createApi({
   reducerPath:'productApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),
   tagTypes:["Product"],
   endpoints: (builder)=>({
   
      newProduct: builder.mutation({
         query:(body)=>{
             return{
                 url:'/admin/new-product',
                 method:'POST',
                 credentials:'include',
                 body
             }
         },
         invalidatesTags:['Product']
     }),
     getProducts:builder.query({
         query:()=>{
           return{
             url:"/admin/all-products",
             method:'GET',
             credentials:'include'
           
 
           }
         },
         providesTags:['Product']
     }),


     getAllProducts:builder.query({
      query:(params)=>{
        return{
          url:"/products",
          method:'GET',
          params:{
            page: params?.page,
            keyword: params?.keyword,
            "price[gte]": params.min,
            "price[lte]": params.max,
            category: params.category,
            "ratings[gte]": params.ratings,
        }

        }
      }
  }),

  getProductDetail:builder.query({
    query:(id)=>{
      return{
        url:`/product/${id}`,
        method:'GET',

      }
    },
    providesTags:['Product']
}),

createReview: builder.mutation({
  query: (body)=>{
     return{
      method:'PUT',
      url: `/new-review`,
      credentials:'include',
      body
     }
  },
  invalidatesTags:['Product']
}),
canUserReview: builder.query({
  query: (id)=>({
      url: `/can-userReview?productId=${id}`,
  }),
  providesTags: ["Product"]
}), 


updateProduct:builder.mutation({
  query:({id,body})=>{
    return{
      url:`/update-product/${id}`,
      credentials: 'include',
      method:'PATCH',
      body
    }
  },
  invalidatesTags:["Product"]

}),

updateImages:builder.mutation({
  query:({id,body})=>{
    return{
      url:`/edit-images/${id}`,
      credentials: 'include',
      method:'PATCH',
      body
    }
  },
  invalidatesTags:["Product"]

}),


deleteProduct:builder.mutation({
    query:(id)=>{
      return{
        url:`/delete-product/${id}`,
        credentials: 'include',
        method:'DELETE'
      }
    },
    transformResponse:(response)=>response,
    async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

        try {
           
           const {data}=await queryFulfilled 
           dispatch({
            type: DELETE_PRODUCT,
            productId: data?._id
            
           })
           dispatch(setLoading(false))
        } catch (error) {
          dispatch(setLoading(false))
        console.log(error)
        }
      },
    invalidatesTags:["Product"]

  }),

  deleteImageProduct:builder.mutation({
    query:({id,imagePath})=>{
      return{
        url:`/delete-productImage/${encodeURIComponent(imagePath)}/${id}`,
        credentials: 'include',
        method:'DELETE'
      }
    },
    transformResponse:(response)=>response,
    async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

        try {
           
           const {data}=await queryFulfilled 
           dispatch({
            type: DELETE_IMAGE_PATH,
            productId: data?._id
            
           })
           dispatch(setLoading(false))
        } catch (error) {
          dispatch(setLoading(false))
        console.log(error)
        }
      },
    invalidatesTags:["Product"]

  }),
 
   })
})

export const {useGetProductsQuery,useNewProductMutation, 
  useGetAllProductsQuery, useGetProductDetailQuery,
  useCreateReviewMutation,useCanUserReviewQuery, 
  useDeleteProductMutation,
  useUpdateProductMutation, useUpdateImagesMutation, useDeleteImageProductMutation}=productApi
