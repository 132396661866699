import React,{useEffect} from 'react'
import AllProductsList from './AllProductsList'
import { useGetAllProductsQuery } from '../../store/api/productApi'
import { useSearchParams } from 'react-router-dom'
import Loader from '../../utils/Loader'
import PaginationPage from '../../helpers/Pagination'
import {toast} from 'react-toastify'

const AllProducts = () => {
  let [searchParams]=useSearchParams()
  const page=searchParams.get("page") || 1;
  const keyword=searchParams.get("keyword") || ""
//   const max=searchParams.get("max") 
// const category=searchParams.get("category")
// const ratings=searchParams.get("ratings")
  const params={page, keyword}
  // min!==null && (params.min=min);
  // max!==null && (params.max=max);
  // category!==null && (params.category=category);
  // ratings!==null && (params.ratings=ratings);
const {data,isLoading, error, isError}=useGetAllProductsQuery(params)

useEffect(()=>{
  if(isError){
    toast.error(error?.data?.message)
  }
},[isError])

if(isLoading) return <Loader/>
  return (
    <div>
      <section className="trending-product section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title">
                        <h2>Products</h2>
                       
                    </div>
                </div>
            </div>

            <div className="row">
                
            {
                data?.products?.map((items,idx)=>(

                  <AllProductsList product={items} key={idx} />
                ))
              }
           
           </div>
           <div className="d-flex flex-row bd-highlight mb-3">        
<PaginationPage recordPage={data?.recordPerPage} filteredProductsCount={data?.filterProductsCount}/>
</div>
        </div>
    </section>
    </div>

  )
}

export default AllProducts
