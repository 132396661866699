import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { get_tax_price, shipping_price,get_total_price} from '../../utils/result'

const Checkout = ({children,step}) => {
const {cartSubtotal}=useSelector(state=>state.cart)

  
    
  return (
  <Fragment>
    <section className="checkout-wrapper section">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 className="title">
                                  {step}
                                    
                                     </h6>
                                <section className="checkout-steps-form-content" id="collapseThree">
                                 {
                                    children
                                 }



                                </section>
                            </li>
                           
                            
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="checkout-sidebar">
                        <div className="checkout-sidebar-coupon  checkout-sidebar-price-table">
                            
                        <div className="sub-total-price">
                                <div className="total-price">
                                    <p className="value">Subotal Price:</p>
                                    <p className="price">${cartSubtotal.toFixed(2)}</p>
                                </div>
                                <div className="total-price shipping">
                                    <p className="value">Tax:</p>
                                    <p className="price">${get_tax_price(cartSubtotal)}</p>
                                </div>
                                <div className="total-price discount">
                                    <p className="value">Shipping:</p>
                                    <p className="price">${shipping_price(cartSubtotal)}</p>
                                </div>
                            </div>

                            <div className="total-payable">
                                <div className="payable-price">
                                    <p className="value">Total Price:</p>
                                    <p className="price">${get_total_price(cartSubtotal)}</p>
                                </div>
                            </div>
                            
                        </div>
                       
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
  </Fragment>
  )
}

export default Checkout
