import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'

import { Link } from 'react-router-dom'

import {toast} from 'react-toastify'
import Loader from '../../../utils/Loader'
import ProductForm from '../form/ProductForm'
import { useNewProductMutation } from '../../../store/api/productApi'

const NewProduct = () => {

    const [values,setValues]=useState({name:'',description:'',images:[],
    category:'',price:'',quantity:'',discount:'',value:'',colors:[]})
   

    const {name,description,images,category,price,quantity,discount,colors}=values
    
    const [previewImages, setPreviewImages] = useState([]);
    const [newProduct,{isError,isLoading,isSuccess,error,data}]=useNewProductMutation()
   

   

    useEffect(()=>{
	
      const files = [], fileReaders = [];
      if(images.length){
        [...images].forEach(item=>{
    
        const fileReader = new FileReader();
            fileReaders.push(fileReader);
            fileReader.onload = (e) => {
              const { result } = e.target;
              if (result) {
                files.push(result)
              }
              if (files.length===images.length) {
              setPreviewImages(files)
              }
            }
            fileReader.readAsDataURL(item);
    
        })
      }
    
    
    },[images])



  
  

    const handleImagesChange = (e) => {
        let image_files=Array.from(e.target.files)
       
          setValues({ ...values, images: image_files });
        };
      
        const handleChange = (e) => {
          setValues({ ...values, [e.target.name]: e.target.value }); 
         
          
        };
       
        
        

    const handleSubmit =  (e) => {
      e.preventDefault();
      const formData= new FormData()
      Array.from(images).forEach((image)=>{
        formData.append('images',image)
        })

      
     
  

    formData.append('colors',colors)
      formData.append("name", name);
      formData.append("description", description);
      formData.append("price",price)
      formData.append("quantity",quantity)
      formData.append("discount",discount)
      formData.append("category",category)
      
      
      
  
     try {
      newProduct(formData)
     
      
      
     } catch (er) {
       console.log(er)
     }
  
    }


    useEffect(()=>{
       if(isSuccess){
        toast.success(data?.message)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
       }
    },[isSuccess])


    useEffect(()=>{
      if(isError){
       toast.error(error?.data?.message)
       
      }
   },[isError])


  if(isLoading) return <Loader/>
  return (
  <Fragment>
<MetaData title={"new category"}/>
<section className="product-grids section">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-12">
             
                <div className="product-sidebar">
             
                    
                 
                    <div className="single-widget">
                        <h3><Link to="/dashboard">Dashboard</Link></h3>
                        <ul className="list">
                        <li>
                                <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                            </li>
                            <li>
                                <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                            </li>
                            <li>
                            <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                            </li>
                            <li>
                            <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                            </li>
                            <li>
                            <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                            </li>
                            <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                        </ul>
                    </div>
              
                 
                  
                    
                </div>
               
            </div>


            <div className="col-lg-9 col-12">
                <div className="product-grids-head">
            <div className="tab-content" id="nav-tabContent">

<div className="tab-pane fade show active" id="nav-grid" role="tabpanel"
    aria-labelledby="nav-grid-tab">


 
 <ProductForm previewImages={previewImages} values={values} handleChange={handleChange} 
 handleImagesChange={handleImagesChange}  handleSubmit={handleSubmit}/>





    

</div>



</div>

</div>
</div>
</div>
</div>
</section>
  </Fragment>
  )
}

export default NewProduct