import { createSlice } from "@reduxjs/toolkit";



const messageSlice=createSlice({
    name:"messageSlice",
    initialState:{
      message: null,
      loading:true
    },
    
    reducers:{
        SetMessage:(state,action)=>{
       
            
              state.message=action.payload
            
        },
       

       
        setLoading:(state,action)=>{
          state.loading=action.payload
        },
    
        DELETE_MESSAGE:(state,action)=>{
         const {payload}=action;
          state.message= Array.from(state.message).filter(x=>x.id!==payload.messageId)
          
        }


    }
})

export const {SetMessage,DELETE_MESSAGE, setLoading}=messageSlice.actions;
export default messageSlice.reducer;