import React from 'react'

const ForgotPasswordForm = ({handleChange,handleSubmit, values,isLoading}) => {
  const {email}=values||''
  return (
    <div>
       <form class="card login-form"  onSubmit={handleSubmit}>
                        <div class="card-body">
                            <div class="title">
                                <h3>Forgot Password</h3>
                                {/* <p>You can login using your social media account or email address.</p> */}
                            </div>
                           
                            <div class="form-group input-group">
                                <label htmlFor="email">Email</label>
                                <input class="form-control" name="email" value={email} onChange={handleChange} type="email" id="email" />
                            </div>
                          
                          
                            <div class="button">
                                <button disabled={isLoading} class="btn" type="submit">{isLoading?"Loading...":"Submit"}</button>
                            </div>
                            
                            
                        </div>
                    </form>
      
    </div>
  )
}

export default ForgotPasswordForm
