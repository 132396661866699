import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {DELETE_COMPANY, SetCompany, setLoading } from '../reducers/companySlice'
export const companyApi=createApi({
   reducerPath:'companyApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),
   tagTypes:["Company"],
   endpoints: (builder)=>({
    company: builder.query({
      query: ()=> {return {url:'/company',method:"GET",credentials: 'include'}},
    
        transformResponse:(response)=>response,
        async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

            try {
               
               const {data}=await queryFulfilled 
               dispatch(SetCompany(data))
               dispatch(setLoading(false))
            } catch (error) {
              dispatch(setLoading(false))
            console.log(error)
            }
          },
       providesTags: ["Company"]
    }),

    newCompany:builder.mutation({
        query:(body)=>{
            return{
              url:'/create-company',
              credentials: 'include',
              method:'POST',
              body
            }
          },
          invalidatesTags:["Company"]
    }),
    companyDetails:builder.query({
        query:(id)=>{
            return{
              url:`/company-detail/${id}`,
              credentials: 'include',
              method:'GET'
            }
          },

    }),
    updateCompany:builder.mutation({
      query:({id,body})=>{
        return{
          url:`/edit-company/${id}`,
          credentials: 'include',
          method:'PATCH',
          body
        }
      },
      invalidatesTags:["Company"]

    }),
  
  getCompany: builder.query({
    query:()=>{
      return{
        url:'/getcompany',
        method:'GET'
      }
    }
  }),
    deleteCompany:builder.mutation({
        query:(id)=>{
          return{
            url:`/delete-company/${id}`,
            credentials: 'include',
            method:'DELETE'
          }
        },
        transformResponse:(response)=>response,
        async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

            try {
               
               const {data}=await queryFulfilled 
               dispatch({
                type: DELETE_COMPANY,
                companyId: data?._id
                
               })
               dispatch(setLoading(false))
            } catch (error) {
              dispatch(setLoading(false))
            console.log(error)
            }
          },
        invalidatesTags:["Company"]
  
      }),
    
   

 
 
   })
})

export const {useCompanyQuery, useDeleteCompanyMutation,
    useUpdateCompanyMutation, useNewCompanyMutation, 
    useCompanyDetailsQuery,useGetCompanyQuery}=companyApi
