import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetOrderDetailsQuery } from '../../store/api/orderApi'
import MetaData from '../../utils/MetaData'
import Loader from '../../utils/Loader'
import {toast} from 'react-toastify'
import OrderDetailsItems from './OrderDetailsItems'

const OrderDetails = () => {
const params=useParams()
const {orderId}=params
    const {isError, isLoading, error,data}=useGetOrderDetailsQuery(orderId)
  const [orders, setOrders]=useState([])
    const navigate=useNavigate()
useEffect(()=>{
    if(isError){
        toast.error(error?.data?.message)
    }
setOrders(data)
},[isError,data])

const redirectToInvoice=()=>{
navigate(`/invoice/${orderId}`)
}

    if(isLoading) return <Loader/>
  return (
    <Fragment>
        <MetaData title={"order-detail"}/>
        <div className="breadcrumbs">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="breadcrumbs-content">
                        <h1 className="page-title">My Orders</h1>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <ul className="breadcrumb-nav">
                        <li><Link to="/my-orders"><i className="lni lni-home"></i>Got to Order</Link></li>
                        <li><Link to="/">Shop</Link></li>
                        <li>Order</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="shopping-cart section">
        <div className="container">
            <div className="cart-list-head">
           
                <div className="cart-list-title">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 col-12">

                        </div>
                        <div className="col-lg-4 col-md-3 col-12">
                            <p>Product Name</p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <p>Quantity</p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <p>Price</p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <p>Subtotal</p>
                        </div>
                        
                    </div>
                </div>




{
  orders &&  orders?.orderItems?.map((items,idx)=>(
        <OrderDetailsItems key={idx} items={items}/>
    ))
}


    
            
    </div>
            
            <div className="row">
                <div className="col-12">
                  
                    <div className="total-amount">
                        <div className="row">
                        
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="right">
                                    <ul>
                                        <li>Subtotal<span>${orders?.itemsPrice}</span></li>
                                        <li>Shipping<span>{orders?.shippingAmount>0?+orders?.shippingAmount:"Free"}</span></li>
                                        <li>Tax<span>${orders?.taxAmount}</span></li>
                                        <li className="last">You Pay<span>${orders?.totalAmount}</span></li>
                                    </ul>
                                    <div className="button">
                                        <button onClick={redirectToInvoice} className="btn">Invoice</button>
                                        
                                    </div>
                                </div>
                            </div>


                            
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
    </Fragment>
  )
}

export default OrderDetails
