import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { REMOVE_ITEM_FROM_CART,ADD_ITEM_TO_CART } from '../../store/reducers/cartSlice'
import { getDiscountPrice } from '../../utils/result'

const CartItems = ({items}) => {
    const dispatch=useDispatch()

    const navigate=useNavigate()
    
    
     const decreaseQty=(quantity)=>{
    
       const newQty = quantity-1;
    
       if(newQty<=0) return toast.info("you can not decrease no more")
    
       setItemsToCart(newQty)
      
     }
    
     const increaseQty=(quantity)=>{
       
       const newQty= quantity+1;
       if(newQty>items?.stock) return toast.info("you can not add no more")
        setItemsToCart(newQty)
        
     }
    
    
     const setItemsToCart=(newQty)=>{
       const cartItem={
         productID:items?.productID,
         name: items?.name,
         price: items?.price,
         image: items?.image,
         quantity: items?.quantity,
         color:items?.color,
         qty: newQty
       }
       dispatch(ADD_ITEM_TO_CART(cartItem))
      navigate(0)
     
    
      
     }
    
    
      const removeItemFromCart=(item)=>{
        const cartItem={
          productID:item?.productID,
          price: item?.price,
          
          qty: item?.qty
        }
    
        dispatch(REMOVE_ITEM_FROM_CART(cartItem))
        navigate(0)
      }

  return (
   <Fragment>
   


              
                <div class="cart-single-list">
                    <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 col-12">
                            <Link to={`product/${items?.productID}`}><img src={`https://ervadinepetitdeboutique.com/${items?.image}`} alt="#"/></Link>
                        </div>
                        <div class="col-lg-4 col-md-3 col-12">
                            <h5 class="product-name"><Link to={`product/${items?.productID}`}>
                                    {items?.name}</Link></h5>
                             <p class="product-des">
                              
                                <span><em>Color:</em> {items?.color}</span>
                            </p> 
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            

                        <div className="form-group quantity">
                                        <label for="color">Quantity</label>
                                        <div class="input-group">
          <span className="input-group-btn">
              <button type="button" onClick={()=>decreaseQty(items?.qty)} className="btn btn-default btn-number" data-type="minus" >
                  <span className="fas fa-minus"></span>
              </button>
          </span>
          <input type="number" className="form-control input-number"  
          id='count'
            value={items?.qty}/>
          <span className="input-group-btn">
              <button type="button" onClick={()=>increaseQty(items?.qty)} className="btn btn-default btn-number" data-type="plus">
                  <span className="fas fa-plus"></span>
              </button>
          </span>
      </div>
                                    </div>




                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>${(items?.price).toFixed(2)}</p>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>${(items?.price*items?.qty).toFixed(2)}</p>
                        </div>
                        <div class="col-lg-1 col-md-2 col-12">
                            <Link class="remove-item" onClick={()=>removeItemFromCart(items)}><i class="lni lni-close"></i></Link>
                        </div>
                    </div>
                </div>
               
            
            

   </Fragment>
  )
}

export default CartItems
