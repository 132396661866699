import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Loader from '../../utils/Loader'
const ProtectedRoutes = ({children}) => {
    const {isAuthenticated,loading,user}=useSelector(state=>state.auth)
    if(loading) return <Loader/>
    if(!isAuthenticated || user?.role!=="admin"){
        return <Navigate to="/login" replace/>
    }
  return  children
  
}

export default ProtectedRoutes