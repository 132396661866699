import { createSlice } from "@reduxjs/toolkit";



const categorySlice=createSlice({
    name:"categorySlice",
    initialState:{
      categories: null,
      loading:true
    },
    
    reducers:{
        SetCategory:(state,action)=>{
       
            
              state.categories=action.payload
            
        },
       

       
        setLoading:(state,action)=>{
          state.loading=action.payload
        },
    
        DELETE_CATEGORY:(state,action)=>{
         const {payload}=action;
          state.categories= Array.from(state.categories).filter(x=>x.id!==payload.categoryId)
          
        }


    }
})

export const {SetCategory,DELETE_CATEGORY, setLoading}=categorySlice.actions;
export default categorySlice.reducer;