import { createSlice } from "@reduxjs/toolkit";



const orderSlice=createSlice({
    name:"orderSlice",
    initialState:{
      orders: null,
      loading:true
    },
    
    reducers:{
        SetOrder:(state,action)=>{
       
            
              state.orders=action.payload
            
        },
       

       
        setLoading:(state,action)=>{
          state.loading=action.payload
        },
    
        DELETE_ORDER:(state,action)=>{
         const {payload}=action;
          state.orders= Array.from(state.orders).filter(x=>x.id!==payload.orderId)
          
        }


    }
})

export const {SetOrder,DELETE_ORDER, setLoading}=orderSlice.actions;
export default orderSlice.reducer;