import React from 'react'
import { Link } from 'react-router-dom'
const CheckoutStep = ({checkout,shipping,payment}) => {
  return (
    <div className="checkout-progress d-flex justify-content-center mt-5">
  {
    checkout ? <Link to="/checkout" className="float-right">
    <div className="triangle2-active"></div>
    <div className="step active-step">Shipping Info</div>
    <div className="triangle-active"></div>
  </Link>:<Link to="#!" className="float-right" disabled>
      <div className="triangle2-incomplete"></div>
      <div className="step incomplete">Shipping Info</div>
      <div className="triangle-incomplete"></div>
    </Link>
  }
   
{
    shipping ?<Link to="/shipping" className="float-right">
    <div className="triangle2-active"></div>
    <div className="step active-step">Billing Address</div>
    <div className="triangle-active"></div>
  </Link>:  <Link to="#!" className="float-right" disabled>
      <div className="triangle2-incomplete"></div>
      <div className="step incomplete">Billing Address</div>
      <div className="triangle-incomplete"></div>
    </Link>

}

    
{
    payment? <Link href="/payment" className="float-right">
    <div className="triangle2-active"></div>
    <div className="step active-step">Payment</div>
    <div className="triangle-active"></div>
  </Link>: <Link to="#!" className="float-right" disabled>
      <div className="triangle2-incomplete"></div>
      <div className="step incomplete">Payment</div>
      <div className="triangle-incomplete"></div>
    </Link>
}
    

    
  

 
   

 
   
  </div>
  )
}

export default CheckoutStep