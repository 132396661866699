import React, { Fragment, useEffect, useState, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
import 'react-medium-image-zoom/dist/styles.css'
import { useCreateReviewMutation} from '../../store/api/productApi'
import { getDiscountPrice } from '../../utils/result'
import CreateReview from '../reviews/CreateReview'
import ReviewsList from '../reviews/ReviewsList'
import { ADD_ITEM_TO_CART } from '../../store/reducers/cartSlice'

const ProductDetailsItem = ({product,ControlledZoom}) => {
    const [ratings, setRatings]=useState(0)
    const [values, setValues]=useState({comment:''})
    const {comment}=values
    
   const {isAuthenticated,user}=useSelector(state=>state.auth)
   const [isZoomed, setIsZoomed] = useState(false)
     const [activeImage, setActiveImage]=useState("")
     const [quantity, setQuantity]=useState(1)
    const [selectedColor,setSelectedColor]=useState("")

     const [createReview, {data,isError,error,isSuccess}]=useCreateReviewMutation()
    const dispatch=useDispatch()
 
    const handleZoomChange = useCallback(shouldZoom => {
     setIsZoomed(shouldZoom)
   }, [])
 
   const handleChange = (e) => {
     setValues({ ...values, [e.target.name]: e.target.value });
   }

   const onSelectedHandler=(e)=>{

        setSelectedColor(e.target.value)
    
    
   }
   
   const submitHandlerReview=(e)=>{
     e.preventDefault()
     const review={
       comment: comment,
       rating: ratings,
       productId: product?._id
     }
    console.log(review)
     createReview(review)
     document.getElementById("exampleModal").classList.remove("show");
     setTimeout(()=>{
        window.location.reload()
     },1000)
   }

   const decreaseQty=()=>{
    const input = document.getElementById("count");
    let count = input.value;

    if(count <=1) return

    const qty=count- 1;
    setQuantity(qty)
  }

  const increaseQty=()=>{
    const input = document.getElementById("count");
    let count = input.value;

    if(count >= product.stock) return

        const qty=++count;

    setQuantity(qty)
  }




  const setItemsToCart=()=>{
  
    
  if(selectedColor===''){
    alert("please choose a color")
    return
  }

  const cartItem={
    productID:product?._id,
    name: product?.name,
    price: product?.price,
    image: product?.images[0].path,
    quantity: product?.quantity,
    color: selectedColor,
    qty: quantity
  }
dispatch(ADD_ITEM_TO_CART(cartItem))
   
toast.success("item added to the cart")
  
 
   
  }

  useEffect(()=>{
    if(isError){
        toast.error(error?.data.message)
    }
   
},[isError])

useEffect(()=>{
  if(isSuccess){
      toast.success(data?.message)
  }
 
},[isSuccess])


  useEffect(()=>{
setActiveImage(`https://ervadinepetitdeboutique.com/${product?.images[0].path}`)
  },[product])
    
  return (
   <Fragment>
   
 
    <section className="item-details section">
        <div className="container">
            <div className="top-area">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-8 col-8">
                        <div className="product-images">
                            <main id="gallery">
                                <div className="main-img">
                                   <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                                   <img src={activeImage} style={{width:'300',height:'350'}} id="current" alt={product?.name}/>
                                   </ControlledZoom>
                                </div>
                                <div className="images">
                               {
                                product?.images.map((item,idx)=>{
                                const url=`https://ervadinepetitdeboutique.com/${item.path}`;

                                  return  <img key={idx} 
                                    src={url} 
                                    
                                    className={`img cursor-pointer ${url===activeImage ? "border border-warning":""}`}
                                    onClick={(e)=>setActiveImage(url)}
                                    
                                     alt={""}/>
                                    
                            
})
                               }
                           </div>
                            </main>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="product-info">
                            <form>
                            <h2 className="title">{product?.name}</h2>
                            
                          {getDiscountPrice(product?.price,product?.discount)}
                           
                            <div className="row">
                              
                              
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="form-group quantity">
                                        <label for="color">Quantity</label>
                                        <div class="input-group">
          <span className="input-group-btn">
              <button type="button" onClick={decreaseQty} className="btn btn-default btn-number" data-type="minus" >
                  <span className="fas fa-minus"></span>
              </button>
          </span>
          <input type="number" className="form-control input-number"  
          id='count'
            value={quantity}/>
          <span className="input-group-btn">
              <button type="button" onClick={increaseQty} className="btn btn-default btn-number" data-type="plus">
                  <span className="fas fa-plus"></span>
              </button>
          </span>
      </div>
                                    </div>
                                </div>



                                <div className="col-lg-4 col-md-4 col-12">
                                 


                                    <div className="form-group color-option">
                                        <label className="title-label" for="size">Choose color</label>
                                    
                                       {
                                     
                                        product?.colors?.map(item=>(


                                            <div className="col-xs-8">
                                            <input type="radio"  onChange={onSelectedHandler} id="color" name="color" value={item} required/>
                                            <label for="checkbox-1"><span>{item}</span></label>
                                        </div>
                                        ))
                                         
                                       }
                                      
                                     
                                       
                                    </div>
                                 
                                   
                                </div>




                            </div>
                            <div className="bottom-content">
                                <div className="row align-items-end">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="button cart-button">
                                            <button type='button'  onClick={setItemsToCart} className="btn" style={{width: "100%;"}}>Add to Cart</button>
                                        </div>
                                    </div>
                                  
                                 
                                </div>
                            </div>

                          </form>

                        </div>
                    </div>
                 



                </div>
            </div>
            <div className="product-details-info">
                <div className="single-block">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="info-body custom-responsive-margin">
                                <h4>Details</h4>
                                <p>{product?.description}</p>
                                {/* <h4>Features</h4>
                                <ul className="features">
                                    <li>Capture 4K30 Video and 12MP Photos</li>
                                    <li>Game-Style Controller with Touchscreen</li>
                                    <li>View Live Camera Feed</li>
                                    <li>Full Control of HERO6 Black</li>
                                    <li>Use App for Dedicated Camera Operation</li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="info-body">
                                {/* <h4>Specifications</h4>
                                <ul className="normal-list">
                                    <li><span>Weight:</span> 35.5oz (1006g)</li>
                                    <li><span>Maximum Speed:</span> 35 mph (15 m/s)</li>
                                    <li><span>Maximum Distance:</span> Up to 9,840ft (3,000m)</li>
                                    <li><span>Operating Frequency:</span> 2.4GHz</li>
                                    <li><span>Manufacturer:</span> GoPro, USA</li>
                                </ul> */}
                                {/* <h4>Shipping Options:</h4>
                                <ul className="normal-list">
                                    <li><span>Courier:</span> 2 - 4 days, $22.50</li>
                                    <li><span>Local Shipping:</span> up to one week, $10.00</li>
                                    <li><span>UPS Ground Shipping:</span> 4 - 6 days, $18.00</li>
                                    <li><span>Unishop Global Export:</span> 3 - 4 days, $25.00</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">


                  

                   <ReviewsList  review={product?.ratings} reviews={product?.reviews} isAuthenticated={isAuthenticated} />

                </div>
            </div>
        </div>
    </section>
   
   
       <CreateReview  submitHandlerReview={submitHandlerReview} values={values} ratings={ratings} handleChange={handleChange} changeRating={(e)=>setRatings(e)}/>
    
     
 

   </Fragment>
  )
}

export default ProductDetailsItem
