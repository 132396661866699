import React, { Fragment, useState, useEffect} from 'react'
import MetaData from '../../../utils/MetaData'

import {toast} from 'react-toastify'
import { Link ,useNavigate} from 'react-router-dom'
import Loader from '../../../utils/Loader'
import { useCompanyQuery, useDeleteCompanyMutation } from '../../../store/api/companyApi'
import CompanyItem from './CompanyItem'

const Company = () => {
const {isError,isLoading,data,error}=useCompanyQuery()
console.log(data)

const [ deleteCompany,{}]=useDeleteCompanyMutation()

const navigation=useNavigate()


useEffect(()=>{
    if(isError){
        toast.error(error?.data.message)
    }
   
},[isError])

const deleteCompanyHandler=(id)=>{
    deleteCompany(id)
    
}
const editCompanyHandler=(id)=>{
    window.location.href=`/#/admin/edit-company/${id}`
}




    if(isLoading) return <Loader/>
  return (
    <Fragment>
    <MetaData title={"company"}/>
    <section className="product-grids section">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-12">
                 
                    <div className="product-sidebar">
                 
                        
                     
                        <div className="single-widget">
                            <h3><Link to="/dashboard">Dashboard</Link></h3>
                            <ul className="list">
                            <li>
                                    <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                                </li>
                                <li>
                                    <Link to="/admin/company"><i className="fas fa-house-user"></i>Company</Link>
                                </li>
                                <li>
                                    <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                                </li>
                                <li>
                                <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                                </li>
                                <li>
                                <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                                </li>
                                <li>
                                <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                                </li>
                                <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                                
                            </ul>
                        </div>
                  
                     
                      
                        
                    </div>
                   
                </div>
    
    
                <div className="col-lg-9 col-12">
                    <div className="product-grids-head">
                <div className="tab-content" >
    
   



                <table className='table'> 
<thead className='thead-light'>
<tr>
      <th scope="col">Name</th>
      <th scope="col">E-Mail</th>
      <th scope="col">Phone</th>
      <th scope="col">Handle</th>
    </tr>
</thead>


<tbody>

{data && <CompanyItem company={data} editCompanyHandler={editCompanyHandler} deleteCompanyHandler={deleteCompanyHandler} />}

</tbody>
</table>




</div>

</div>
</div>
</div>
</div>
</section>    
</Fragment>
  )
}

export default Company
