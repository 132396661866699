import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'


const ViewOrderItems = ({items}) => {
  return (
    <Fragment>
     <div class="cart-single-list">
                    <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 col-12">
                            <Link to={`product/${items?.productID}`}><img src={`https://ervadinepetitdeboutique.com/${items?.image}`} alt="#"/></Link>
                        </div>
                        <div class="col-lg-4 col-md-3 col-12">
                            <h5 class="product-name"><Link to={`product/${items?.productID}`}>
                                    {items?.name}</Link></h5>
                             <p class="product-des">
                              
                                <span><em>Color:</em> {items?.color}</span>
                            </p> 
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            

                        <div className="form-group quantity">
                                        
                                        <div class="input-group">
       
                                  <span>{items?.qty} </span>
         
      </div>
                                    </div>




                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>${(items?.price).toFixed(2)}</p>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                            <p>${(items?.price*items?.qty).toFixed(2)}</p>
                        </div>
                        
                    </div>
                </div>
  </Fragment>
  )
}

export default ViewOrderItems