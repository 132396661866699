import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { getDiscountPrice } from '../../utils/result';
import StarRatings from 'react-star-ratings'
const ProductsList= ({product}) => {

  

    const  tweakFunction=(str)=> {
        var words = str?.match(/([^-]+)/g) || [];
  words.forEach(function(word, i) {
    words[i] = word[0].toUpperCase() + word.slice(1);
  });
  return words.join(' ');
     }
  return (
   
<Fragment>



   






                                
                                    <div className="col-lg-4 col-md-6 col-12">
                                   
                                        <div className="single-product">
                                            <div className="product-image">
                                                <img width={"300px"} height={"320px"} src={`https://ervadinepetitdeboutique.com/${product?.images[0].path}`} alt={product?.name}/>
                                                <div className="button">
                                                <Link to={`/product/${product?._id}`} className="btn"><i className="lni lni-eye"></i>View Details</Link>
                                                </div>
                                            </div>
                                            <div className="product-info">
                                                <span className="category">{tweakFunction(product?.slug)}</span>
                                                <h4 className="title">
                                                <Link to={`/product/${product?._id}`}  >{product?.name}</Link>
                                                </h4>
                                                <ul className="review">
                                                <StarRatings
          rating={product?.ratings}
          starRatedColor="#ffb829"
          numberOfStars={5}
        
          starDimension='22px'
         
        /><br></br>
                                                    <li><span>{product?.numOfReviews} Review(s)</span></li>
                                                </ul>
                                                <div className="price">
                                                    <span>{getDiscountPrice(product?.price, product?.discount)}</span>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>


                                
                               










</Fragment>
 
  )
}

export default ProductsList
