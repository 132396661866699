import React from 'react'

const CategoryForm = ({ values,
    handleChange, handleSubmit,handleImageChange,preview}) => {
        const {name,description}=values ||{}
  return (
    <section className="contact-us section">
   <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="checkout-steps-form-style-1">
                        <ul id="accordionExample">
                            <li>
                                <h6 class="title">Add New Category</h6>
                                <section class="checkout-steps-form-content collapse show" id="collapseThree"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                         <h5 className="card-title">
              {preview?<img
              src={preview}
              alt="preview_image"
              className="img img-fluid m-2"
              style={{width:"45px",height:"55px"}}
            />:''}
              </h5>
                                 <form onSubmit={handleSubmit}>
                                 <div class="row">
                                        <div class="col-md-12">
                                            <div class="single-form form-default">
                                                <label>Name</label>
                                                <div class="row">
                                                    <div class="col-md-6 form-input form">
                                                        <input name="name" type="text" id="name" value={name} onChange={handleChange}/>
                                                    </div>

                                                    <div class="col-md-6 form-input form">
                                                        <input name="image" type="file" id="image"  onChange={handleImageChange}/>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="single-form form-default">
                                                <label>Description</label>
                                                <textarea className='form-control' name="description" cols="60" rows="8" id="description" value={description} onChange={handleChange}></textarea>
                                            </div>
                                        </div>
                                     
                                        
                                      
                                     
                                        
                                        <div class="col-md-12">
                                            <div class="single-form button">
                                                <button class="btn" type='submit'
                                                   >Add New Category</button>
                                            </div>
                                        </div>
                                    </div>
                                 </form>
                                </section>
                            </li>
                           
                           
                           


                        </ul>
                    </div>
                </div>
               
            </div>
      
         
        </div>
    </section>
    
  )
}

export default CategoryForm