import React, { Fragment,useEffect } from 'react'
import MetaData from '../../../utils/MetaData'
import Loader from '../../../utils/Loader'
import {toast} from 'react-toastify'
import { useNavigate} from 'react-router-dom'
import {MDBDataTable} from 'mdbreact'
import { useGetOrderByAdminQuery } from '../../../store/api/orderApi'

const OrderItems = () => {
const {isError,isLoading,data,error}=useGetOrderByAdminQuery()
console.log(data)

const navigation=useNavigate()
useEffect(()=>{
    if(isError){
        toast.error(error?.data.message)
    }
   
},[isError])


const viewOrderHandler=(id)=>{
    navigation(`/admin/view-orders/${id}`)
}
const editOrderHandler=(id)=>{
    navigation(`/admin/edit-order/${id}`)
}

const setOrders=()=>{
    const orders={
       columns:[
           {
           label:'Customer',
           field:'customer',
           sort:'asc'
       },
       {
        label:'Payment Status',
        field:'paymentStatus'
    },
    {
        label:'Amount',
        field:'amount'
    },
    {
        label:'Status',
        field:'status'
    },
       
       {
           label:'Actions',
           field:'actions',
           sort:'asc'
       }

   ],
       rows:[]

    }
    data?.orders?.forEach((item)=>{
       orders.rows.push({
           customer: item?.user?.name,
           paymentStatus: item?.paymentInfo?.status,
           amount: "$"+item?.totalAmount,
           status: item?.orderStatus,
           actions:<><button onClick={()=>viewOrderHandler(item?._id)} className='btn btn-primary'><i className='fa fa-eye'></i></button> <button onClick={()=>editOrderHandler(item?._id)} className='btn btn-info'><i className='fa fa-edit'></i></button>
           </>

       })
    })
    return orders
   }



    if(isLoading) return <Loader/>

    
  return (
    <Fragment>
    <MetaData title={"orders"}/>
    <MDBDataTable

data={setOrders()}
className="px-3"
bordered
striped
hover

noBottomColumns={true}
/>
</Fragment>
  )
}

export default OrderItems
