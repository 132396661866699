import React,{Fragment, useState,useEffect} from 'react'
import MetaData from '../../../utils/MetaData'
import Loader from '../../../utils/Loader'
import { Link, useParams,useNavigate } from 'react-router-dom'
import ViewOrderItems from './ViewOrderItems'
import { useGetOrderDetailsQuery } from '../../../store/api/orderApi'
import { toast } from 'react-toastify'

const ViewOrders = () => {
    const params=useParams()
    const {orderId}=params
        const {isError, isLoading, error,data}=useGetOrderDetailsQuery(orderId)
      const [orders, setOrders]=useState([])
        const navigate=useNavigate()
    useEffect(()=>{
        if(isError){
            toast.error(error?.data?.message)
        }
    setOrders(data)
    },[isError,data])
    
    const redirectToInvoice=()=>{
    navigate(`/admin/invoice/${orderId}`)
    }
    if(isLoading) return <Loader/>
  return (
    <Fragment>
        <MetaData title={"order-detail"}/>
    
        <section class="product-grids section">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12">
                 
                    <div class="product-sidebar">
                 
                        
                     
                        <div class="single-widget">
                            <h3><Link to="/dashboard">Dashboard</Link></h3>
                            <ul class="list">
                            <li>
                                    <Link to="/dashboard"><i class="fas fa-salesforce"></i> Sales </Link>
                                </li>
                                <li>
                                    <Link to="/admin/products"><i class="fas fa-list"></i> Products </Link>
                                </li>
                                <li>
                                <Link to="/admin/category"><i class="fas fa-list"></i> Categories </Link>
                                </li>
                                <li>
                                <Link to="/admin/users"><i class="fas fa-users"></i> Users </Link>
                                </li>
                                <li>
                                <Link to="/admin/orders"><i class="fas fa-list-alt"></i> Orders </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-product"><i class="fas fa-plus-circle"></i> Add Product </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-category"><i class="fas fa-plus-circle"></i> Add Category </Link>
                                </li>
                                <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                            </ul>
                        </div>
                  
                     
                      
                        
                    </div>
                   
                </div>
    
    
                <div class="col-lg-9 col-12">
                    <div class="product-grids-head">
                <div class="tab-content" >




            <div className="cart-list-head">
           
                <div className="cart-list-title">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 col-12">

                        </div>
                        <div className="col-lg-4 col-md-3 col-12">
                            <p>Product Name</p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <p>Quantity</p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <p>Price</p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <p>Subtotal</p>
                        </div>
                        
                    </div>
                </div>




{
  orders &&  orders?.orderItems?.map((items,idx)=>(
        <ViewOrderItems key={idx} items={items}/>
    ))
}


    
            
    </div>
            
            <div className="row">
                <div className="col-12">
                  
                    <div className="total-amount">
                        <div className="row">
                        
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="right">
                                    <br></br>
                                    <div className="button">
                                        <button onClick={redirectToInvoice} className="btn">Invoice</button>
                                        
                                    </div>
                                </div>
                            </div>


                            
                        </div>
                    </div>
                  
                </div>
            </div>


    


    </div>

</div>
</div>
</div>
</div>
</section> 
    </Fragment>
  )
}

export default ViewOrders
