import React, { Fragment} from 'react'
import { Link, useParams } from 'react-router-dom'



const OrderSuccess = () => {
    const params=useParams()
    const {paymentId}=params;
 


   

   

    

   
  return (
<Fragment>
<div class="maill-success">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="success-content">
          <i class="fa fa-check" aria-hidden="true"></i>
           {paymentId && <> <h2>Thank you for your shopping with us.</h2>
            <p>Please click the link below to see your order</p>
            <div class="button">
              <Link to="/my-orders" class="btn">View Order</Link>
            </div></>}
          </div>
        </div>
      </div>
    </div>
  </div>
</Fragment>
  )
}

export default OrderSuccess