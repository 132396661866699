import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ContactForm from '../form/ContactForm'
import { useGetCompanyQuery } from '../../store/api/companyApi'
import Loader from '../../utils/Loader'
import { toast } from 'react-toastify'
import { useNewMessageMutation } from '../../store/api/messageApi'
const Contact = () => {
    const {isLoading,data:company}=useGetCompanyQuery()
    const [values,setValues]=useState([{email:"",subject:'',message:'',name:"",phone:""}])
    const [loading,setIsLoading]=useState(false)
    const {email,subject,message,name,phone}=values

    const [ newMessage,{isError,isSuccess,error,data}]=useNewMessageMutation()

    const handleChange=(e)=>{
         setValues({...values,[e.target.name]:e.target.value})
    }

    const handleSubmit=(e)=>{
      e.preventDefault()

        try {
            const messageData={
                email,subject,message,name,phone
            }
            setIsLoading(true)
            newMessage(messageData)

            if(!data?.message){
                setIsLoading(true)
            }
            
        } catch (error) {
            console.log(error)
        }

       
    }

    useEffect(()=>{
        if(isError){
       toast.error(error?.data?.message)
        }
    },[isError])

    useEffect(()=>{
        if(isSuccess){
       toast.success(data?.message)
       setIsLoading(false)
     setTimeout(()=>{
        window.location.reload()
     },2000)
        }
    },[isSuccess])

    if(isLoading) return <Loader/>
  return (
<Fragment>

    <div className="breadcrumbs">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="breadcrumbs-content">
                        <h1 className="page-title">Contact Us</h1>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <ul className="breadcrumb-nav">
                        <li><Link to="/home"><i className="lni lni-home"></i> Home</Link></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <section id="contact-us" className="contact-us section">
        <div className="container">
            <div className="contact-head">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Contact Us</h2>
                            <p>If you have any question regarding of your order or something else don't hesitate to contact us.</p>
                        </div>
                    </div>
                </div>
                <div className="contact-info">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="single-info-head">
                           
                              {
                              company?.map((item,idx)=>(

                                <>
                                
                                <div className="single-info">
                                    <i className="lni lni-map"></i>
                                    <h3>Address</h3>
                                    <ul>
                                        <li>{item?.address}. {item?.city},<br/> {item?.state} {item?.zipCode}, USA.</li>
                                    </ul>
                                </div>
                               
                                <div className="single-info">
                                    <i className="lni lni-phone"></i>
                                    <h3>Call us on</h3>
                                    <ul>
                                        
                                        <li><a href={`tel: ${item?.phone}`}>+1 {item?.phone}</a></li>
                                    </ul>
                                </div>
                              
                                <div className="single-info">
                                    <i className="lni lni-envelope"></i>
                                    <h3>Mail at</h3>
                                    <ul>
                                        
                                        <li><a href={`mailto:${item?.email}`}>{item?.email}</a></li>
                                    </ul>
                                </div></>
                              ))

                              }
                             
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-12">
                            <div className="contact-form-head">
                                <div className="form-main">

                                   <ContactForm no_error={!error} isLoading={loading} values={values} handleChange={handleChange} handleSubmit={handleSubmit}/>


                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</Fragment>
  )
}

export default Contact