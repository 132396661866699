import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import { DELETE_MESSAGE, SetMessage } from '../reducers/messageSlice'
import { setLoading } from '../reducers/companySlice'
export const messageApi=createApi({
   reducerPath:'messageApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),
   tagTypes:["Message"],
   endpoints: (builder)=>({
    messages: builder.query({
      query: ()=> {return {url:'/messages',method:"GET",credentials: 'include'}},
    
        transformResponse:(response)=>response,
        async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

            try {
               
               const {data}=await queryFulfilled 
               dispatch(SetMessage(data))
               dispatch(setLoading(false))
            } catch (error) {
              dispatch(setLoading(false))
            console.log(error)
            }
          },
       providesTags: ["Message"]
    }),

    newMessage:builder.mutation({
        query:(body)=>{
            return{
              url:'/create-message',
              method:'POST',
              body
            }
          },
          invalidatesTags:["Message"]
    }),
    messageDetails:builder.query({
        query:(id)=>{
            return{
              url:`/message-detail/${id}`,
              credentials: 'include',
              method:'GET'
            }
          },

    }),

  
 
    deleteMessage:builder.mutation({
        query:(id)=>{
          return{
            url:`/delete-message/${id}`,
            credentials: 'include',
            method:'DELETE'
          }
        },
        transformResponse:(response)=>response,
        async onQueryStarted( arg,{ dispatch, getState, queryFulfilled} ) {

            try {
               
               const {data}=await queryFulfilled 
               dispatch({
                type: DELETE_MESSAGE,
                messageId: data?._id
                
               })
               dispatch(setLoading(false))
            } catch (error) {
              dispatch(setLoading(false))
            console.log(error)
            }
          },
        invalidatesTags:["Message"]
  
      }),
    
   

 
 
   })
})

export const {useMessageDetailsQuery,useMessagesQuery,useDeleteMessageMutation,useNewMessageMutation}=messageApi
