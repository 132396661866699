import React from 'react'

const ResetPasswordForm = ({handleChange,handleSubmit, values,isLoading}) => {
  const {confirmPassword, password}=values||''
  return (
    <div>
       <form class="card login-form"  onSubmit={handleSubmit}>
                        <div class="card-body">
                            <div class="title">
                                <h3>Reset Your Password</h3>
                                {/* <p>You can login using your social media account or email address.</p> */}
                            </div>
                           
                            <div class="form-group input-group">
                                <label htmlFor="password">Password</label>
                                <input class="form-control" name="password" value={password} onChange={handleChange} type="password" id="password" />
                            </div>
                            <div class="form-group input-group">
                                <label htmlFor='confirmPassword'>Confirm Password</label>
                                <input class="form-control" name='confirmPassword' value={confirmPassword} onChange={handleChange} type="password" id="confirmPassword"/>
                            </div>
                          
                            <div class="button">
                                <button disabled={isLoading} class="btn" type="submit">{isLoading?"Loading...":"Reset Password"}</button>
                            </div>
                           
                        </div>
                    </form>
      
    </div>
  )
}

export default ResetPasswordForm
