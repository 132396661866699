import React from 'react'
import { useGetCategoriesQuery } from '../../../store/api/categoryApi'



const UpdateProductForm = ({values,handleChange,
    handleSubmit}) => {
    const { name, description, quantity, price,discount, colors}=values||{}

    const {data}=useGetCategoriesQuery()

  return (
   
                                            <form onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Name</label>
                                                <div className="form-input form">
                                                    <input type="text" name='name' defaultValue={name} onChange={handleChange} placeholder="Product Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Price</label>
                                                <div className="form-input form">
                                                    <input type="text" name='price' defaultValue={price} onChange={handleChange} placeholder="Product Price"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Category</label>
                                                <div className="form-input form">
                                                   <select name='category' className='form-control' onChange={handleChange}>
                                                    <option value="">select..</option>
                                                    {
                                                        data?.map((item,idx)=>(
                                                            <option key={idx} value={item.slug}>{item.name}</option>
                                                        ))
                                                    }
                                                   </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Quantity</label>
                                                <div className="form-input form">
                                                    <input type="text" name='quantity' value={quantity} onChange={handleChange} placeholder="Product Quantity"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Discount</label>
                                                <div className="form-input form">
                                                    <input type="text" name='discount' defaultValue={discount} onChange={handleChange} placeholder="Product Discount"/>
                                                </div>
                                            </div>
                                        </div>
                                     

                                        <div className="col-md-6">
                                            <div className="single-form form-default">
                                                <label>Product Color</label>
                                                <div className="form-input form">
                                                    <input type="text" name='colors' defaultValue={colors} onChange={handleChange} placeholder="Product Color"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="single-form form-default">
                                                <label>Description</label>
                                                <textarea className='form-control' name="description" defaultValue={description} onChange={handleChange} cols="60" rows="8" id="description" ></textarea>
                                            </div>
                                        </div>
                                    
                                        <div className="col-md-12">
                                            <div className="single-form button">
                                                <button className="btn" type='submit'
                                                   >Edit Product</button>
                                            </div>
                                        </div>
                                       
                                    </div>

                                    </form>


    
  )
}

export default UpdateProductForm