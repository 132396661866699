import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Carousel from './Carousel'
import AllProducts from './AllProducts'
import MetaData from '../../utils/MetaData'
import ProductByCategory from './ProductByCategory'
import { useGetCategoriesQuery } from '../../store/api/categoryApi'
import Loader from '../../utils/Loader'
import {toast} from 'react-toastify'

const Main = () => {

    const {isLoading,isError,error,data}=useGetCategoriesQuery()
    useEffect(()=>{
        if(isError){
        toast.error(error?.data?.messsage)
        }
    },[])


    if(isLoading) return <Loader/>
  return (
    <Fragment>
<MetaData title={"home"}/>

<section className="featured-categories section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title">
                        <h2>Categories</h2>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* <div className="row">

{
    data?.map(items=>(
        <ProductByCategory product={items}/>
    ))
}
</div> */}


<main>

  

  <div className="album py-5 bg-light">
    <div className="container">

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
     
       
      

     
      {
    data?.map(items=>(
        <ProductByCategory product={items}/>
    ))
}
     

     
      

       



      </div>
    </div>
  </div>

</main>




        </div>
    </section>
    <AllProducts/>
    </Fragment>
  )
}

export default Main
