import React from 'react'

const ContactForm = ({handleChange, handleSubmit, values,isLoading=false,no_error}) => {

const {email,subject,message,name,phone}=values
    
  return (
    <form className="form"  onSubmit={handleSubmit} >
    <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <input name="name" type="text" placeholder="Your Name" value={name}
                onChange={handleChange}
                    required="required"/>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <input name="subject" type="text" placeholder="Your Subject"
                value={subject}
                onChange={handleChange}
                    required="required"/>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <input name="email" type="email" placeholder="Your Email"
                value={email}
                onChange={handleChange}
                    required="required"/>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <input name="phone" type="text" placeholder="000-000-0000"
                value={phone}
                onChange={handleChange}
                    required="required"/>
            </div>
        </div>
        <div className="col-12">
            <div className="form-group message">
                <textarea name="message" placeholder="Your Message" value={message} onChange={handleChange}></textarea>
            </div>
        </div>
        <div className="col-12">
            <div className="form-group button">
                <button type="submit" className="btn ">
                    {
                        no_error && isLoading? <> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</>:"Submit Message"
                    }
                    </button>
            </div>
        </div>
    </div>
</form>
  )
}

export default ContactForm