import React, { Fragment,useEffect,useState } from 'react'
import MetaData from '../../utils/MetaData'
import {useSearchParams } from 'react-router-dom'
import ProductsList from './ProductsList'
import { Link } from 'react-router-dom'
import {toast} from 'react-toastify'
import PaginationPage from '../../helpers/Pagination'
import { useGetAllProductsQuery } from '../../store/api/productApi'
import Loader from '../../utils/Loader'
import { getPriceQueryParams } from '../../helpers/helper'
import { PRODUCT_RATINGS } from '../../helpers/constants'
import { useNavigate } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { useGetCategoriesQuery } from '../../store/api/categoryApi'
const Products = () => {
    const [proMin,setProMin]=useState(0)
    const [proMax,setProMax]=useState(0)


    const navigate=useNavigate()
    let [searchParams]=useSearchParams()
    const page=searchParams.get("page") || 1;
    const keyword=searchParams.get("keyword") || ""
    const min=searchParams.get("min") 
    const max=searchParams.get("max") 
  const category=searchParams.get("category")
  const ratings=searchParams.get("ratings")
    const params={page, keyword}
    min!==null && (params.min=min);
    max!==null && (params.max=max);
    category!==null && (params.category=category);
    ratings!==null && (params.ratings=ratings);

const {data,isLoading, error, isError}=useGetAllProductsQuery(params)
const {data:getCategorie}=useGetCategoriesQuery()




const handleButtonClick=(e)=>{
    e.preventDefault();
    searchParams=getPriceQueryParams(searchParams,"min",proMin)
    searchParams=getPriceQueryParams(searchParams,"max",proMax)
 const path=window.location.pathname + "?" + searchParams.toString()
navigate(path)
}

const handleCategoryClick=(checkbox)=>{
    const checkboxes=document.getElementsByName(checkbox.name)
    checkboxes.forEach((item=>{
        if(item!==checkbox) item.checked=false
      
    }))

    if(checkbox.checked===false){
      if(searchParams.has(checkbox.name)){
        searchParams.delete(checkbox.name)
      }
      const path=window.location.pathname + "?" + searchParams.toString()
      navigate(path)
    }else{
      //set new value if already there
      if(searchParams.has(checkbox.name)){
       searchParams.set(checkbox.name, checkbox.value)
      }else{
        //append the filter
        searchParams.append(checkbox.name, checkbox.value)
      }

      const path=window.location.pathname + "?" + searchParams.toString()
      navigate(path)
    }
}

const  resetFilter=()=>{
  const path='/'


      navigate(path)
}

const defaultCheckHandler=(checkboxType, checkboxValue)=>{
    const value=searchParams.get(checkboxType)

    if(checkboxValue===value) return true
    return false
}

useEffect(()=>{
  searchParams.has('min') && setProMin(searchParams.get('min'))
  searchParams.has('max') && setProMax(searchParams.get('max'))
},[])






useEffect(()=>{
    if(isError){
      toast.error(error?.data?.message)
    }
  },[isError])


  if(isLoading) return <Loader/>
  return (
  < Fragment>
  <MetaData title={"Products"}/>
  <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="breadcrumbs-content">
                        <h1 class="page-title">Ervadine Petit De Boutique</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <ul class="breadcrumb-nav">
                        <li><Link to="/home"><i class="lni lni-home"></i> Home</Link></li>
                        <li><Link to="/">Products</Link></li>
                        <li>{category}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <section class="product-grids section">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12">
                 
                    <div class="product-sidebar">
                 
                        <div class="single-widget search">
                            <h3>Filter By Rating</h3>
                            {
      PRODUCT_RATINGS.map((rating,idx)=>(

        <div className="form-check" key={idx}>
        <input
          className="form-check-input"
          type="checkbox"
          name="ratings"
          id="check7"
          defaultChecked={defaultCheckHandler("ratings", rating.toString())}
          onClick={(e)=>handleCategoryClick(e.target)}
          value={rating}
        />
        <label className="form-check-label" for="check7">
          <span className="star-rating">

          <StarRatings
          rating={rating}
          starRatedColor="#ffb829"
          numberOfStars={5}
          name='rating'
          starDimension='21px'
        />
          </span>
        </label>
      </div>
      ))
    }
                        </div>
                     
                        {/* <div class="single-widget">
                            <h3>All Categories</h3>
                            <ul class="list">
                                <li>
                                    <a href="product-grids.html">Computers & Accessories </a><span>(1138)</span>
                                </li>
                                <li>
                                    <a href="product-grids.html">Smartphones & Tablets</a><span>(2356)</span>
                                </li>
                                <li>
                                    <a href="product-grids.html">TV, Video & Audio</a><span>(420)</span>
                                </li>
                                <li>
                                    <a href="product-grids.html">Cameras, Photo & Video</a><span>(874)</span>
                                </li>
                                <li>
                                    <a href="product-grids.html">Headphones</a><span>(1239)</span>
                                </li>
                                <li>
                                    <a href="product-grids.html">Wearable Electronics</a><span>(340)</span>
                                </li>
                                <li>
                                    <a href="product-grids.html">Printers & Ink</a><span>(512)</span>
                                </li>
                            </ul>
                        </div> */}
                  
                       
                        <div class="single-widget condition">
                            <h3>Filter by Price</h3>
                            <form onSubmit={handleButtonClick}
        id="filter_form"
        className="px-2"
        method="post"
      >
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Min ($)"
              name="min"
              value={min}
              onChange={(e)=>setProMin(e.target.value)}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Max ($)"
              name="max"
              value={max}
              onChange={(e)=>setProMax(e.target.value)}
            />
          </div>
          <div className="col">
            <button type="submit" className="btn btn-primary">GO</button>
          </div>
        </div>
      </form>
                        </div>
                     
                        <div class="single-widget condition">
                            <h3>Filter by Category</h3>
                           
                                {
                                    getCategorie?.map((item,idx)=>(

                                        <div key={idx} class="form-check">
                                        <input  class="form-check-input" type="checkbox"
                                          name="category"
                                          id="check5"
                                          defaultChecked={defaultCheckHandler("category", item)}
                                          onClick={(e)=>handleCategoryClick(e.target)}
                                        
                                        value={item?.slug}/>
                                <label class="form-check-label" for="flexCheckDefault11">
                                    {item?.name}
                                </label>
                                </div>
                                    ))
                                }
                           
                         




                        </div>

                        <div class="single-widget condition">

                        <div className='row'>
       <div className='col'>
      <button onClick={resetFilter} className='btn btn-info' type='button'>Reset Filter</button>
       </div>
      </div>
                   </div>       
                    </div>



                   
                </div>



                <div class="col-lg-9 col-12">



                    <div class="product-grids-head">
                        <div class="product-grid-topbar">
                            <div class="row align-items-center">
                                <div class="col-lg-7 col-md-8 col-12">
                                    <div class="product-sorting">
                                        {/* <label for="sorting">Sort by:</label>
                                        <select class="form-control" id="sorting">
                                            <option>Popularity</option>
                                            <option>Low - High Price</option>
                                            <option>High - Low Price</option>
                                            <option>Average Rating</option>
                                            <option>A - Z Order</option>
                                            <option>Z - A Order</option>
                                        </select>
                                        <h3 class="total-show-product">Showing: <span>1 - 12 items</span></h3> */}
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-4 col-12">
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button class="nav-link active" id="nav-grid-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-grid" type="button" role="tab"
                                                aria-controls="nav-grid" aria-selected="true"><i
                                                    class="lni lni-grid-alt"></i></button>
                                            <button class="nav-link" id="nav-list-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-list" type="button" role="tab"
                                                aria-controls="nav-list" aria-selected="false"><i
                                                    class="lni lni-list"></i></button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>


                        <div class="tab-content" id="nav-tabContent">

                            <div class="tab-pane fade show active" id="nav-grid" role="tabpanel"
                                aria-labelledby="nav-grid-tab">




<div class="row">
{
                data?.products?.map((items,idx)=>(

                  <ProductsList product={items} key={idx} />
                ))
              }

</div>



<div className="d-flex flex-row bd-highlight mb-3">        
<PaginationPage recordPage={data?.recordPerPage} filteredProductsCount={data?.filterProductsCount}/>
</div>

    </div>



</div>

</div>
</div>
</div>
</div>
</section>
   </Fragment>
  )
}

export default Products
