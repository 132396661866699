import React from 'react'
import { Link } from 'react-router-dom'
import { getDiscountPrice } from '../../utils/result';
import StarRatings from 'react-star-ratings'
import { ADD_ITEM_TO_CART } from '../../store/reducers/cartSlice'
import {useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
const AllProductsList = ({product}) => {

    const dispatch=useDispatch()
  
    const  tweakFunction=(str)=> {
        var words = str?.match(/([^-]+)/g) || [];
  words.forEach(function(word, i) {
    words[i] = word[0].toUpperCase() + word.slice(1);
  });
  return words.join(' ');
     }

     const setItemsToCart=()=>{
        const cartItem={
          productID:product?._id,
          name: product?.name,
          price: product?.price,
          image: product?.images[0].path,
          quantity: product?.quantity,
          color: product?.colors,
          qty: 1
        }
        dispatch(ADD_ITEM_TO_CART(cartItem))
       
        toast.success("item added to the cart")
     
       
      }
    



  return (
 
    
    

    <div className="col-lg-3 col-md-6 col-12">
                
                    <div className="single-product">
                        <div className="product-image">
                            <img width={"300px"} height={"320px"} src={`https://ervadinepetitdeboutique.com/${product?.images[0].path}`} alt={product?.name}/>
                            <div className="button">
                                <Link to={`/product/${product?._id}`} className="btn"><i className="lni lni-eye"></i>View Details</Link>
                            </div>
                        </div>
                        <div className="product-info">
                            <span className="category">{tweakFunction(product?.slug)}</span>
                            <h4 className="title" >
                                <Link to={`/product/${product?._id}`} >{product?.name}</Link>
                            </h4>
                            <ul className="review">
                            <StarRatings
          rating={product?.ratings}
          starRatedColor="#ffb829"
          numberOfStars={5}
        
          starDimension='22px'
         
        /><br></br>
                                <li><span>{product?.numOfReviews} Review(s)</span></li>
                            </ul>
                            <div className="price">
                                <span>{getDiscountPrice(product?.price, product?.discount)}</span>
                            </div>
                        </div>
                        </div>


            </div> )



}

export default AllProductsList
