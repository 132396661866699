import {createSlice} from '@reduxjs/toolkit'
const cartItemFromStorage= localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [];

const cartSlice=createSlice({
    name:'cartSlice',
    initialState:{
      cartItems: cartItemFromStorage,
      itemsCount:cartItemFromStorage?cartItemFromStorage.reduce((qty,item)=>Number(item.qty)+ qty,0):0,
      cartSubtotal:cartItemFromStorage?cartItemFromStorage.reduce((price,item)=>price+item.price*item.qty,0):0,
      shippingInfo:localStorage.getItem("shippingInfo") ? JSON.parse(localStorage.getItem("shippingInfo")) : {}
    },
    reducers:{
        ADD_ITEM_TO_CART: (state,action)=>{
          const item= action.payload
            const existingItem=state.cartItems.find(x=>x.productID===item.productID)
          
            if(existingItem){
                 
                   state.cartSubtotal=0;
                    state.itemsCount=0;
                    state.cartItems= state.cartItems.map((x)=>{
                      if(x.productID===existingItem.productID){
                        state.itemsCount +=Number(item.qty)
                        const sum=Number(item.qty)*Number(item.price)
                        state.cartSubtotal +=sum
                       
                      }else{
                        state.itemsCount+=Number(x.qty)
                        const sum=Number(x.qty)*Number(x.price)
                        state.cartSubtotal+=sum
                       
                      }
                      return x.productID===item.productID?item:x
                    })
                  
            
            }else{
              
              state.itemsCount+=Number(item.qty);
              const sum=Number(item.qty)*Number(item.price);
              state.cartSubtotal+=sum
              state.cartItems=[...state.cartItems,item]
             
            }
            localStorage.setItem('cartItems',JSON.stringify(state.cartItems,state.itemsCount,state.cartSubtotal))
          
        },

        REMOVE_ITEM_FROM_CART: (state,action)=>{
          const item=action.payload;

          const existingItem=state.cartItems.filter(x=>x.productID!==item.productID)
          if(existingItem){
            state.cartItems=existingItem
            state.cartSubtotal=Number(state.cartSubtotal)-item.price*item.qty
            state.itemsCount= Number(state.itemsCount)-item.qty
          }
          localStorage.setItem('cartItems',JSON.stringify(state.cartItems,state.itemsCount,state.cartSubtotal))
          return state;
          
      },
      SHIPPING_INFO:(state,action)=>{
        
       state.shippingInfo=action.payload
       localStorage.setItem('shippingInfo',JSON.stringify(state.shippingInfo))
       return state;
      },
      REMOVE_SHIPPING_INFO:(state,action)=>{
               state.shippingInfo=""
               localStorage.removeItem("shippingInfo")

               return state;
      },
      ALL_ITEMS_FROM_CART:(state,action)=>{

       
            state.cartItems=[];
            state.itemsCount=0;
            state.cartSubtotal=0;
          
          localStorage.removeItem('cartItems');
          return state;
    },

      
        
    }
})

export const {ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART, SHIPPING_INFO, REMOVE_SHIPPING_INFO, ALL_ITEMS_FROM_CART}=cartSlice.actions
export default cartSlice.reducer