import React,{useEffect,useState} from 'react'
import Pagination from "react-js-pagination";
import {useNavigate, useSearchParams} from 'react-router-dom'


const PaginationPage = ({filteredProductsCount,recordPage}) => {

  const [currentPage,setCurrentPage]=useState()
  let [searchParams]=useSearchParams()
  const page=Number(searchParams.get("page")) || 1;
  const navigate=useNavigate()
  useEffect(()=>{
    setCurrentPage(page)
  },[page])

  const handlePageChange=(pageNumber)=>{
    
  setCurrentPage(pageNumber)
  if(searchParams.has("page")){
    searchParams.set("page", pageNumber)
  }else{
    searchParams.append("page", pageNumber)
  }
  const path=window.location.pathname + "?" + searchParams.toString()
  navigate(path)
  }
  return (
   
                           
                                     
                                 
      <>
       {
        filteredProductsCount>recordPage && <Pagination
        activePage={currentPage}
        itemsCountPerPage={recordPage}
        totalItemsCount={filteredProductsCount}
        onChange={handlePageChange}
        containerClassName={"pagination"}
 
        nextPageText={"Next"}
        prevPageText={"Prev"}
        firstPageText={"First"}
        lastPageText={"Last"}
        itemClass="page-item"
        linkClass="page-link"
        />
      }
      </>
       
                                     
                                    
                           
                             
    
  )
}

export default PaginationPage
