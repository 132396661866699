import React,{Fragment, useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetOrderDetailsQuery, useUpdateOrderMutation } from '../../../store/api/orderApi'
import { useState } from 'react'
import Loader from '../../../utils/Loader'
import { toast } from 'react-toastify'
import UpdateOrderForm from '../form/UpdateOrderForm'
const UpdateOrder = () => {
const params=useParams()
const orderId=params?.orderId
const [values,setValues]=useState({orderStatus:'',tracking:''})

const {orderStatus, tracking}=values;

const {isLoading,data}=useGetOrderDetailsQuery(orderId)
const [updateOrder,{isError,isSuccess, error,data:info}]=useUpdateOrderMutation()
const navigation=useNavigate()



useEffect(()=>{

    setValues({...values,...data})
  
   
},[orderId,data])

const handleChange=(e)=>{
    setValues({...values,[e.target.name]: e.target.value})
}

const handleSubmit=(e)=>{
    e.preventDefault()
    try {
        const statusData={
            orderStatus: orderStatus,
            tracking: tracking
        }
        updateOrder({id:orderId,body: statusData})
        
    } catch (error) {
        console.log(error)
    }
}
useEffect(()=>{
    if(isError){
     toast.error(error?.data.message)
    }
},[isError])

useEffect(()=>{
    if(isSuccess){
     toast.success(info?.message)
     setTimeout(()=>{
        window.location.href='/admin/orders'
     },2000)
    }
},[isSuccess])

if(isLoading) return <Loader/>
  return (
   
    <Fragment>
          <section className="product-grids section">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-12">
                 
                    <div className="product-sidebar">
                 
                        
                     
                        <div className="single-widget">
                            <h3><Link to="/dashboard">Dashboard</Link></h3>
                            <ul className="list">
                            <li>
                                    <Link to="/dashboard"><i className="fas fa-salesforce"></i> Sales </Link>
                                </li>
                                <li>
                                    <Link to="/admin/products"><i className="fas fa-list"></i> Products </Link>
                                </li>
                                <li>
                                <Link to="/admin/category"><i className="fas fa-list"></i> Categories </Link>
                                </li>
                                <li>
                                <Link to="/admin/users"><i className="fas fa-users"></i> Users </Link>
                                </li>
                                <li>
                                <Link to="/admin/orders"><i className="fas fa-list-alt"></i> Orders </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-product"><i className="fas fa-plus-circle"></i> Add Product </Link>
                                </li>
                                <li>
                                <Link to="/admin/add-category"><i className="fas fa-plus-circle"></i> Add Category </Link>
                                </li>
                                <li>
                            <Link to="/admin/add-company"><i className="fas fa-plus-circle"></i> Add Company </Link>
                            </li>
                            </ul>
                        </div>
                  
                     
                      
                        
                    </div>
                   
                </div>
    
    
                <div className="col-lg-9 col-12">
                    <div className="product-grids-head">
                <div className="tab-content" >
<UpdateOrderForm values={values} handleChange={handleChange} handleSubmit={handleSubmit}/>






</div>

</div>
</div>
</div>
</div>
</section>  
    </Fragment>
  )
}

export default UpdateOrder
