import React from 'react'
import { Link } from 'react-router-dom'

const RegisterForm = ({handleChange,handleSubmit,values, isLoading}) => {

    const {name,email,password,confirmPassword,phoneNumber}=values||''
  return (
    <div>
        <form className="row" method="post" onSubmit={handleSubmit}>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name"> Name</label>
                                    <input className="form-control" name='name' value={name} onChange={handleChange} type="text" id="name" />
                                </div>
                            </div>
                           
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="email">E-mail Address</label>
                                    <input className="form-control" name='email' value={email} onChange={handleChange} type="email" id="email" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input className="form-control" name='phoneNumber' value={phoneNumber} onChange={handleChange} type="text" id="phone" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-pass">Password</label>
                                    <input className="form-control" name='password' value={password} onChange={handleChange} type="password" id="reg-pass" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-pass-confirm">Confirm Password</label>
                                    <input className="form-control" name='confirmPassword' value={confirmPassword} onChange={handleChange} type="password" id="reg-pass-confirm"/>
                                </div>
                            </div>
                            <div className="button">
                            <button disabled={isLoading} class="btn" type="submit">{isLoading?"Loading...":"Register"}</button>
                            </div>
                            <p className="outer-link">Already have an account? <Link to="/login">Login Now</Link>
                            </p>
                        </form>
    </div>
  )
}

export default RegisterForm
