import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'


export const orderApi=createApi({
   reducerPath:'orderApi',
   baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_API_URL}),
   tagTypes:["Order"],

   endpoints: (builder)=>({
    createOrder: builder.mutation({
        query:(body)=>{
            return{
                url:'/new-order',
                method:'POST',
                credentials: 'include',
                body
            }
        },
        invalidatesTags:['Order']
    }),
    updateOrder:builder.mutation({
        query:({id,body})=>{
            return{
                url:`/admin/update-order/${id}`,
                method:'PATCH',
                credentials: 'include',
                body
            }
        },
        invalidatesTags:['Order']
    }),

    createPayment:builder.mutation({
      query:(data)=>{
            return{
                url:`/payment`,
                method:'POST',
                credentials: 'include',
                body: data
            }
      }
    }),
    getStripeApiKey:builder.query({
    query:()=>{
        return{
            url:`/stripeapi`,
            method:'GET',
            credentials: 'include',
        
        }
    }
    }),
    getOrders: builder.query({
        query:()=>{
           return {
              method:'GET',
              url:'/user/orders',
              credentials: 'include',
              
           }
        }
     }),
     getOrderByAdmin:builder.query({
        query:()=>{
            return {
                method:'GET',
                url:'/admin/orders',
                credentials: 'include',
            }
        }

     }),
     getOrderDetails: builder.query({
        query: (id)=>({
            url: `/order/${id}`,
            credentials: 'include',
            method:'GET'
        })
    }),
    checkSalesByDates: builder.query({
     query:({startDate, endDate})=>{
        return {
           method:'GET',
           url:`/admin/get-sales/?startDate=${startDate}&endDate=${endDate}`,
           credentials: 'include',
           
        }
     }
  }),


   })


})


export const {
    useLazyCheckSalesByDatesQuery, 
    useCreateOrderMutation,
    useGetOrderDetailsQuery,
    useGetOrdersQuery,
    useCreatePaymentMutation,
    useGetStripeApiKeyQuery, useGetOrderByAdminQuery, useUpdateOrderMutation}=orderApi;